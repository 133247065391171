import { Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { hasRole, useLayout } from "wcz-layout";
import { useGetMaterialOptions } from "../../queries/MaterialQueries";
import { useGetSearch } from "../../queries/SearchQueries";
import AuthPolicy from "../../utils/AuthPolicy";
import { CustomNoRowsOverlay } from "../common/CustomNoRowsOverlay";
import { AboutMe } from "./advancedSearch/AboutMe";
import { SearchEmployeeCard } from "./advancedSearch/SearchEmployeeCard";
import { SearchInput } from "./advancedSearch/SearchInput";
import { SearchLocationCard } from "./advancedSearch/SearchLocationCard";
import { SearchMaterialCard } from "./advancedSearch/SearchMaterialCard";
import EmployeesWithNewId from "./advancedSearch/filters/EmployeesWithNewId";
import MaterialOfInactiveEmployees from "./advancedSearch/filters/MaterialOfInactiveEmployees";
import MaterialWithoutLocation from "./advancedSearch/filters/MaterialWithoutLocation";
import MaterialWithoutQuantity from "./advancedSearch/filters/MaterialWithoutQuantity";

const TAKE = 10;

export const AdvancedSearch: React.FC = () => {
    const queryClient = useQueryClient();
    const { t } = useLayout();
    const [searchParams, setSearchParams] = useSearchParams();
    const value = searchParams.get("value") ?? "";
    const [debouncedValue, setDebouncedValue] = useState<string>("");
    const filterActive = !!debouncedValue;

    const { data, isFetching, hasNextPage, fetchNextPage } = useGetSearch({ value: debouncedValue, take: TAKE }, {
        enabled: filterActive,
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            const totalMaterials = allPages.reduce((acc, page) => acc + page.materials.length, 0);
            const totalLocations = allPages.reduce((acc, page) => acc + page.locations.length, 0);
            const totalEmployees = allPages.reduce((acc, page) => acc + page.employees.length, 0);
            const total = totalMaterials + totalLocations + totalEmployees;

            if (total < (lastPage.totalMaterials + lastPage.totalLocations + lastPage.totalEmployees))
                return allPages.length + 1;
        }
    });

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
                if (hasNextPage && !isFetching) {
                    fetchNextPage();
                }
            }
        };

        const checkContentHeight = () => {
            if (document.body.offsetHeight <= window.innerHeight) {
                if (hasNextPage && !isFetching) {
                    fetchNextPage();
                }
            }
        };

        checkContentHeight();
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasNextPage, isFetching]);

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const changeValue = (value: string) => {
        setSearchParams({ value });

        if (timeoutRef.current)
            clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            queryClient.cancelQueries({ queryKey: ["infiniteSearch"], exact: false });
            setDebouncedValue(value);
        }, 700);
    };

    const dataLength = data.pages.reduce((acc, page) => acc + page.materials.length + page.locations.length + page.employees.length, 0);

    const firstEmployeesPageIndex = data.pages.findIndex(page => page.employees.length > 0);
    const firstMaterialsPageIndex = data.pages.findIndex(page => page.materials.length > 0);
    const firstLocationsPageIndex = data.pages.findIndex(page => page.locations.length > 0);

    const { data: materialOptions } = useGetMaterialOptions({ enabled: filterActive });

    return (
        <Fragment>
            <SearchInput value={value} setValue={changeValue} isFetching={isFetching} dataLength={dataLength} />
            {(!filterActive && hasRole(AuthPolicy.Admin)) &&
                <Stack direction="row" spacing={1} sx={{ overflow: "auto" }}>
                    <MaterialWithoutLocation />
                    <MaterialWithoutQuantity />
                    <MaterialOfInactiveEmployees />
                    <EmployeesWithNewId />
                </Stack>
            }

            {(!filterActive && !hasRole(AuthPolicy.Admin)) && <AboutMe />}

            {filterActive &&
                <Stack spacing={2} sx={{ mt: 2 }}>
                    {data?.pages.map((page, index) =>
                        <Fragment key={index}>
                            {index === firstEmployeesPageIndex && <Typography variant="h6">{t("Employees")} ({page.totalEmployees})</Typography>}
                            {page.employees.map(employee => <SearchEmployeeCard key={employee.id} employee={employee} />)}

                            {index === firstMaterialsPageIndex && <Typography variant="h6">{t("Materials")} ({page.totalMaterials})</Typography>}
                            {page.materials.map(material => <SearchMaterialCard key={material.id} material={material} options={materialOptions} />)}

                            {index === firstLocationsPageIndex && <Typography variant="h6">{t("Locations")} ({page.totalLocations})</Typography>}
                            {page.locations.map(location => <SearchLocationCard key={location.id} location={location} />)}
                        </Fragment>
                    )}
                    {(!isFetching && !dataLength) && <CustomNoRowsOverlay title={t("NothingToSeeHere")} />}
                </Stack>
            }
        </Fragment>
    );
};