import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Error, LayoutContext, fetchGet } from "wcz-layout";
import Trail from "../models/Trail";
import { baseUrl } from "../utils/BaseUrl";

const route: string = "v1/Trail";

interface UseGetTrailsParams {
    primaryKey?: string;
    year?: string;
}

export const useGetTrails = (options?: Omit<DefinedInitialDataOptions<Trail[], Error, Trail[]>, "queryKey" | "queryFn" | "initialData">, params?: UseGetTrailsParams) => {
    const { snackbar } = useContext(LayoutContext);

    const queryParams = new URLSearchParams();
    if (params?.primaryKey) queryParams.append("primaryKey", params.primaryKey);
    if (params?.year) queryParams.append("year", params.year.toString());

    const query = useQuery<Trail[], Error, Trail[]>({
        ...options,
        queryKey: [route, queryParams],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}?${queryParams.toString()}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};