import { Box } from "@mui/material";
import { FC } from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel: FC<TabPanelProps> = ({ children, index, value }) => {
    return (
        <Box role="tabpanel" hidden={value !== index} sx={{ width: "100%", overflow: "auto" }}>
            {value === index && children}
        </Box>
    );
};