import { History, QrCode, Search } from "@mui/icons-material";
import { Autocomplete, Box, CircularProgress, InputAdornment, TextField } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { hasRole, LayoutContext, Platform, TypographyWithIcon } from "wcz-layout";
import { useCreateEmployeeSearchHistory, useGetEmployeeSearchHistories } from "../../../queries/EmployeeSearchHistoryQueries";
import AuthPolicy from "../../../utils/AuthPolicy";
import { DynamsoftScanner } from "../../common/DynamsoftScanner";

interface SearchInputProps {
    value: string;
    setValue: (value: string) => void;
    isFetching: boolean;
    dataLength: number | undefined;
}

export const SearchInput: React.FC<SearchInputProps> = ({ value, setValue, isFetching, dataLength }) => {
    const [options, setOptions] = useState<string[]>([]);
    const [blurred, setBlurred] = useState(false);
    const [scannerOpen, setScannerOpen] = useState<boolean>(false);
    const { t } = useContext(LayoutContext);
    const showHistory = !value;

    const { data: employeeSearchHistories = [] } = useGetEmployeeSearchHistories();

    useEffect(() => {
        if (showHistory && employeeSearchHistories.length)
            setOptions(employeeSearchHistories.map(esh => esh.searchText));
        else
            setOptions([]);
    }, [showHistory, employeeSearchHistories]);

    const handleOnInputChange = (value: string, reason?: string) => {
        if (reason === "clear")
            return setValue("");

        setValue(value);
    };

    const { mutate: createEmployeeSearchHistory } = useCreateEmployeeSearchHistory({
        onSuccess: () => setBlurred(false)
    });

    useEffect(() => {
        if (blurred && value && dataLength)
            createEmployeeSearchHistory({ searchText: value });
    }, [blurred, value, dataLength]);

    const handleOnBlur = () => setBlurred(true);

    const canScan = (Platform.isAndroid || Platform.isIOS) && hasRole(AuthPolicy.Admin);

    const handleOnScan = (text: string) => {
        setValue(text);
    };

    return (
        <Fragment>
            <Autocomplete
                value={value}
                options={options}
                autoHighlight
                autoComplete
                clearOnEscape
                freeSolo
                blurOnSelect
                loading={isFetching}
                onInputChange={(e, value, reason) => handleOnInputChange(value, reason)}
                renderInput={(params) =>
                    <TextField {...params} placeholder={t("Search")} autoFocus={Platform.isWindows}
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {isFetching ? <CircularProgress size={24} /> : canScan ? <QrCode onClick={() => setScannerOpen(true)} /> : <Search />}
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                }
                renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                        <TypographyWithIcon startIcon={<History />}>{option}</TypographyWithIcon>
                    </Box>
                )}
                onBlur={handleOnBlur}
            />

            <DynamsoftScanner open={scannerOpen} setOpen={setScannerOpen} onScan={handleOnScan} />
        </Fragment>
    );
};