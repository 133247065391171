import { TableContainer } from "wcz-layout";
import { MaterialDataGrid } from "../../components/material/MaterialDataGrid";
import { useGetMaterials } from "../../queries/MaterialQueries";

export const MaterialsPage: React.FC = () => {
    const { data, isFetching } = useGetMaterials();

    return (
        <TableContainer>
            <MaterialDataGrid data={data} isFetching={isFetching} />
        </TableContainer>
    );
};