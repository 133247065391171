import { Inventory2, KeyboardArrowDown, Laptop, LocationOn, ManageAccounts, Person } from "@mui/icons-material";
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { hasRole, useLayout } from "wcz-layout";
import AuthPolicy from "../../utils/AuthPolicy";

export const NewButton: React.FC = () => {
    const { t } = useLayout();
    const isAdmin = hasRole(AuthPolicy.Admin);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <Box textAlign="right">
            <Button variant="contained" color="primary" endIcon={<KeyboardArrowDown />} onClick={handleClick}>{t("New")}</Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{ paper: { sx: { overflow: "visible", mt: 1.5, }, }, }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {isAdmin &&
                    <MenuItem onClick={() => navigate("/materials/create")}>
                        <ListItemIcon>
                            <Laptop fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t("Material")}</ListItemText>
                    </MenuItem>
                }
                {isAdmin &&
                    <MenuItem onClick={() => navigate("/locations/create")}>
                        <ListItemIcon>
                            <LocationOn fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t("Location")}</ListItemText>
                    </MenuItem>
                }
                {isAdmin &&
                    <MenuItem onClick={() => navigate("/employees/create")}>
                        <ListItemIcon>
                            <Person fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t("Employee")}</ListItemText>
                    </MenuItem>
                }
                {isAdmin &&
                    <MenuItem onClick={() => navigate("/cycle-counts/create")}>
                        <ListItemIcon>
                            <Inventory2 fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t("CycleCount")}</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={() => navigate("/user-cycle-counts/create")}>
                    <ListItemIcon>
                        <ManageAccounts fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("UserCycleCount")}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};