import moment from "moment";
import Employee from "../models/Employee";
import Material from "../models/Material";

//Base
const normalizeValue = (value: any) => {
    return value === "" || value === null || value === undefined ? null : value;
};

export const areObjectsEqual = (obj1: any, obj2: any) => {
    const keys = Array.from(new Set([...Object.keys(obj1), ...Object.keys(obj2)]));
    for (const key of keys) {
        if (normalizeValue(obj1[key]) !== normalizeValue(obj2[key])) {
            return false;
        }
    }
    return true;
};

//Material
export const getWarrantyDate = (orderDate: string, monthsOfWarranty: number) => {
    if (!orderDate)
        return undefined;

    return moment(orderDate).add(monthsOfWarranty, "months").formatDate();
};

export const getCompanyByFixAsset = (fixAsset: string) => {
    if (!fixAsset || fixAsset.length < 4) return undefined;

    const companyCode: string = fixAsset.substring(0, 4);
    switch (companyCode) {
        case "L520": return "WCZ";
        case "L090": return "WSCZ";
        default: return companyCode;
    }
};

//DataGrid functions
export const warrantyDateGetter = (value: number, row: Material) => {
    const orderDate: string | null = row.orderDate;

    if (!orderDate || !value)
        return null;

    return moment(orderDate).add(value, "months").toDate();
};

export const keeperGetter = (value: Employee) => {
    if (value)
        return `${value.firstName} ${value.lastName} (${value.id})`;

    return null;
};