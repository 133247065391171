import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GridToolbar, LayoutContext, TableContainer } from "wcz-layout";
import { TrailValueList } from "../components/trail/TrailValueList";
import Trail from "../models/Trail";
import { useGetTrails } from "../queries/TrailQueries";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import moment from "moment";
import { GridToolbarProps } from "wcz-layout/dist/src/components/dataGrid/GridToolbar";

export const LogsPage: React.FC = () => {
    const { t } = useContext(LayoutContext);
    const [year, setYear] = useState(moment().year().toString());

    const { data, isFetching, refetch } = useGetTrails({ enabled: false }, { year });

    useEffect(() => {
        refetch();
    }, [year]);

    const columns: GridColDef<Trail>[] = [
        { field: "dateTime", headerName: t("Date"), type: "dateTime", width: 200, valueGetter: value => value && new Date(value) },
        { field: "userName", headerName: t("UpdatedBy"), width: 150, },
        { field: "userId", headerName: "ID", width: 150, },
        { field: "type", headerName: t("Type"), width: 150, },
        { field: "tableName", headerName: t("Name"), width: 150, },
        {
            field: "oldValues", headerName: t("OldValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
        {
            field: "newValues", headerName: t("NewValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
    ];

    const handleChange = useCallback((event: SelectChangeEvent) => setYear(event.target.value), []);
    const years = useMemo(() => Array.from({ length: 5 }, (_, i) => moment().year() - i), []);

    return (
        <TableContainer>
            <DataGridPremium
                rows={data}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                loading={isFetching}
                ignoreDiacritics
                getRowHeight={() => "auto"}
                slotProps={{
                    toolbar: {
                        actions: [
                            <Select value={year} onChange={handleChange} size="small" key="years" sx={{ height: 30 }}>
                                {years.map(year => <MenuItem key={year} value={year.toString()}>{year}</MenuItem>)}
                            </Select>
                        ]
                    } as GridToolbarProps
                }}
            />
        </TableContainer>
    );
};