import { Add, ArrowBack, Close, Delete, Done, Edit, LocationOn } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { groupBy } from "lodash";
import { Fragment, useCallback, useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";
import CycleCount from "../../models/CycleCount";
import CycleCountItem from "../../models/CycleCountItem";
import { CycleCountItemStatus } from "../../models/enums/CycleCountItemStatus";
import { useDeleteCycleCountItem, useUpdateCycleCountItem } from "../../queries/CycleCountQueries";
import { BottomPanel } from "./startedCycleCount/BottomPanel";
import { useGetLocations } from "../../queries/LocationQueries";
import { grey } from "@mui/material/colors";

let quantityChangeTimeoutId: NodeJS.Timeout | null = null;

interface StartedCycleCountProps {
    cycleCount: CycleCount;
}

export const StartedCycleCount: React.FC<StartedCycleCountProps> = ({ cycleCount }) => {
    const { t } = useContext(LayoutContext);
    const [selectedLocationId, setSelectedLocationId] = useState<string>("");
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; cycleCountItemId: string } | null>(null);
    const [scannedValue, setScannedValue] = useState<string>("");

    const { data: locations = [] } = useGetLocations(undefined, { isWarehouse: true });

    const groupedByLocationId = Object.keys(groupBy(cycleCount.items, item => item.location.id));

    const handleSetSelectedLocation = useCallback((id: string) => () => setSelectedLocationId(id), []);

    const { mutate: updateCycleCountItem } = useUpdateCycleCountItem(cycleCount.id);

    const handleOnQuantityChange = useCallback((item: CycleCountItem) => (e: React.FocusEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);

        if (quantityChangeTimeoutId)
            clearTimeout(quantityChangeTimeoutId);

        quantityChangeTimeoutId = setTimeout(() => updateCycleCountItem({ ...item, foundQuantity: value }), 700);
    }, [cycleCount]);

    const handleRightClick = useCallback((cycleCountId: string) => (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(contextMenu === null ? { mouseX: event.clientX, mouseY: event.clientY, cycleCountItemId: cycleCountId } : null);
    }, []);

    const handleMenuClose = useCallback(() => setContextMenu(null), []);

    const { mutate: deleteCycleCountItem } = useDeleteCycleCountItem({
        onSuccess: handleMenuClose
    });

    const handleOnDelete = useCallback(() => deleteCycleCountItem(contextMenu!.cycleCountItemId), [contextMenu]);

    const selectedLocationRender = (
        <List subheader={<Typography variant="h6" gutterBottom onClick={handleSetSelectedLocation("")} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <ArrowBack sx={{ mr: 1.2 }} /> {locations.find(l => l.id === selectedLocationId)?.name} ({cycleCount.items?.filter(i => i.location.id === selectedLocationId).length})</Typography>}>

            {cycleCount.items?.filter(i => i.location.id === selectedLocationId)?.map(item => {
                const highValueMaterialInfo = item.material?.fixAsset ?? item.material?.imei ?? item.material?.serialNumber;

                return (
                    <Box id={item.id} key={item.id} onContextMenu={handleRightClick(item.id)}>
                        <ListItem sx={theme => ({ bgcolor: highValueMaterialInfo === scannedValue ? theme.palette.mode === "dark" ? grey[800] : grey[200] : undefined })} secondaryAction={highValueMaterialInfo ?
                            <ButtonGroup>
                                <Button color="success" variant={item.foundQuantity === 1 ? "contained" : "outlined"} onClick={() => updateCycleCountItem({ ...item, foundQuantity: 1 })}><Done /></Button>
                                <Button color="error" variant={item.foundQuantity === 0 ? "contained" : "outlined"} onClick={() => updateCycleCountItem({ ...item, foundQuantity: 0 })}><Close /></Button>
                            </ButtonGroup>
                            :
                            <TextField id={`${item.id}-input-qty`} size="small" defaultValue={item.foundQuantity} onChange={handleOnQuantityChange(item)} sx={{ width: "12.7ch" }} type="number" InputProps={{
                                startAdornment: <InputAdornment position="start">Qty:</InputAdornment>,
                            }} />
                        }>

                            <ListItemIcon>
                                {item.status === CycleCountItemStatus.Ok && <Done color="success" />}
                                {item.status === CycleCountItemStatus.Updated && <Edit color="info" />}
                                {item.status === CycleCountItemStatus.New && <Add color="warning" />}
                            </ListItemIcon>

                            <ListItemText primary={highValueMaterialInfo ?? item.material?.name ?? item.scannedValue} secondary={
                                <Fragment>
                                    {item.material?.partNumber && <Typography variant="body2">{item.material?.partNumber}</Typography>}
                                    {item.material?.serialNumber && <Typography variant="body2">{item.material?.serialNumber}</Typography>}
                                </Fragment>
                            } />
                        </ListItem>
                        <Divider />
                    </Box>
                );
            })}
        </List>
    );

    const locationSelectionRender = (
        <List subheader={<Typography variant="h6" gutterBottom>{cycleCount.items?.length ? `${t("Locations")}:` : t("StartWithScanningLocation")}</Typography>}>
            {groupedByLocationId.map((locationId) => {
                const locationItems = cycleCount.items!.filter(i => i.location.id === locationId);
                const hasWaitingMaterial = locationItems.some(d => d.status === CycleCountItemStatus.Waiting);

                return (
                    <Fragment key={locationId}>
                        <ListItemButton onClick={handleSetSelectedLocation(locationId)}>
                            <ListItemIcon>
                                <LocationOn color={hasWaitingMaterial ? undefined : "success"} />
                            </ListItemIcon>
                            <ListItemText primary={`${locations.find(l => l.id === locationId)?.name} (${locationItems.length})`} secondary={hasWaitingMaterial ? undefined : t("Finished")} />
                        </ListItemButton>
                        <Divider />
                    </Fragment>
                );
            })}
        </List>
    );

    return (
        <Fragment>
            <Box sx={{ mb: 10, mt: 1, mx: 1 }}>
                {selectedLocationId ? selectedLocationRender : locationSelectionRender}
            </Box>
            <BottomPanel cycleCount={cycleCount} selectedLocationId={selectedLocationId} setSelectedLocationId={setSelectedLocationId} setScannedValue={setScannedValue} locations={locations} />

            <Menu open={contextMenu !== null} onClose={handleMenuClose} anchorReference="anchorPosition" variant="menu"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                <List disablePadding>
                    <MenuItem onClick={handleOnDelete}>
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t("File.Delete")}</ListItemText>
                    </MenuItem>
                </List>
            </Menu>
        </Fragment>
    );
};