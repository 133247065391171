import { Person } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLayout } from "wcz-layout";
import Employee from "../../../models/Employee";
import moment from "moment";
import { EmployeeStatus } from "../../../models/enums/EmployeeStatus";
import { grey } from "@mui/material/colors";

interface SearchEmployeeCardProps {
    employee: Employee;
}

export const SearchEmployeeCard: React.FC<SearchEmployeeCardProps> = ({ employee }) => {
    const { t } = useLayout();
    const navigate = useNavigate();

    return (
        <Card variant="outlined">
            <CardActionArea onClick={() => navigate(`/employees/${employee.id}`)} sx={theme => ({ bgcolor: grey[theme.palette.mode === "dark" ? 900 : 100] })}>
                <CardHeader title={`${employee.firstName} ${employee.lastName} (${employee.id})`} subheader={employee.status} avatar={<Person color={employee.status === EmployeeStatus.Active ? "success" : "error"} />} />
            </CardActionArea>
            <CardContent>
                <Typography variant="body2"><b>{t("Department")}:</b> {employee.department}</Typography>
                {employee.company && <Typography variant="body2"><b>{t("Company")}:</b> {employee.company}</Typography>}
                {employee.terminationFutureDate && <Typography variant="body2"><b>{t("TerminationDate")}:</b> {moment(employee.terminationFutureDate).formatDate()}</Typography>}
            </CardContent>
        </Card>
    );
};