import { Container, Stack } from "@mui/material";
import React from "react";
import { AdvancedSearch } from "../components/homepage/AdvancedSearch";
import { NewButton } from "../components/homepage/NewButton";

export const HomePage: React.FC = () => {

    return (
        <Container sx={{ my: 2 }}>
            <Stack spacing={2}>
                <NewButton />
                <AdvancedSearch />
            </Stack>
        </Container>
    );
};