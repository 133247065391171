import { GridRowId } from "@mui/x-data-grid-premium";
import { DefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Error, LayoutContext, fetchDelete, fetchGet, fetchPost } from "wcz-layout";
import Cart, { initCart } from "../models/Cart";
import { baseUrl } from "../utils/BaseUrl";
import CartItem from "../models/CartItem";

const route: string = "v1/Cart";

export const useGetCarts = (options?: Omit<DefinedInitialDataOptions<Cart[], Error, Cart[]>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<Cart[], Error, Cart[]>({
        ...options,
        queryKey: [route],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetCart = (id: GridRowId, options?: Omit<DefinedInitialDataOptions<Cart, Error, Cart>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<Cart, Error, Cart>({
        ...options,
        queryKey: [route, id],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/${id}`, signal),
        initialData: initCart,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetCartItem = (id: GridRowId, options?: Omit<DefinedInitialDataOptions<Cart, Error, Cart>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<Cart, Error, Cart>({
        ...options,
        queryKey: [route, "item", id],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/item/${id}`, signal),
        initialData: initCart,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

interface CreateCartParams {
    preventLocationDelete?: boolean;
}

export const useCreateCart = (params?: CreateCartParams, options?: Omit<UseMutationOptions<Cart, Error, Cart>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    const queryParams = new URLSearchParams();
    params?.preventLocationDelete && queryParams.append("preventLocationDelete", "true");

    return useMutation<Cart, Error, Cart>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}?${queryParams.toString()}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Cart[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [route], exact: false });
            queryClient.invalidateQueries({ queryKey: ["v1/Material"], exact: false });
            queryClient.invalidateQueries({ queryKey: ["v1/search"], exact: false });
        }
    });
};

export const useCreateCartItem = (params?: CreateCartParams, options?: Omit<UseMutationOptions<CartItem, Error, CartItem>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    const queryParams = new URLSearchParams();
    params?.preventLocationDelete && queryParams.append("preventLocationDelete", "true");

    return useMutation<CartItem, Error, CartItem>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}/${model.cartId}/item?${queryParams.toString()}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Cart[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [route], exact: false });
            queryClient.invalidateQueries({ queryKey: ["v1/Material"], exact: false });
            queryClient.invalidateQueries({ queryKey: ["v1/search"], exact: false });
        }
    });
};

export const useDeleteCart = (options?: Omit<UseMutationOptions<GridRowId, Error, GridRowId>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<GridRowId, Error, GridRowId>({
        ...options,
        mutationFn: id => fetchDelete(`${baseUrl}/${route}/${id}`),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Cart[]) => old.filter(prev => prev.id !== id));

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useDeleteCartItem = (options?: Omit<UseMutationOptions<GridRowId, Error, GridRowId>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<GridRowId, Error, GridRowId>({
        ...options,
        mutationFn: id => fetchDelete(`${baseUrl}/${route}/item/${id}`),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Cart[]) => old.filter(prev => prev.id !== id));

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};