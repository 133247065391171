import { History, Laptop, Person, SwapHoriz } from "@mui/icons-material";
import { Autocomplete, Button, Card, CardActions, CardContent, CardHeader, Chip, Container, Grid2, Tab, TextField } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { ZodValidator, zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { hasRole, LayoutContext, TableContainer, TypographyWithIcon } from "wcz-layout";
import { z } from "zod";
import { TabPanel } from "../../components/common/TabPanel";
import { VerticalTabs } from "../../components/common/VerticalTabs";
import { MaterialDataGrid } from "../../components/material/MaterialDataGrid";
import { TrailDataGrid } from "../../components/trail/TrailDataGrid";
import { TransferDataGrid } from "../../components/transfer/TransferDataGrid";
import PaginationFilter from "../../models/base/PaginationFilter";
import Employee from "../../models/Employee";
import { EmployeeStatus } from "../../models/enums/EmployeeStatus";
import { useDeleteEmployee, useGetEmployee, useUpdateEmployee } from "../../queries/EmployeeQueries";
import { useGetMaterials, useGetMaterialTransferSearch } from "../../queries/MaterialQueries";
import { useGetPeoplesoftDepartments } from "../../queries/PeoplesoftQueries";
import { useGetTrails } from "../../queries/TrailQueries";
import AuthPolicy from "../../utils/AuthPolicy";

const materialTransferFilter = (employeeId: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "or",
            filters: [
                { field: "oldKeeper.id", operator: "eq", value: employeeId },
                { field: "newKeeper.id", operator: "eq", value: employeeId },
            ]
        },
        orderBy: ["dateTime desc"]
    };
};

export const EmployeeDetailPage: React.FC = () => {
    const { id } = useParams();
    const { t, snackbar } = useContext(LayoutContext);
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();
    const isAdmin = hasRole(AuthPolicy.Admin);

    const { data } = useGetEmployee(id!, {
        enabled: tab === 0,
        refetchOnWindowFocus: false,
    });

    const { data: peoplesoftDepartments } = useGetPeoplesoftDepartments();

    const { data: materials, isFetching: isFetchingMaterials } = useGetMaterials({ enabled: !!data.id && tab === 1 }, { employeeId: data.id });

    const { data: materialTransferSearch, isFetching: isFetchingMaterialTransfer } = useGetMaterialTransferSearch(materialTransferFilter(data.id), { enabled: !!data.id && tab === 2 });

    const { data: trails, isFetching: isFetchingTrails } = useGetTrails({ enabled: !!data.id && tab === 3 }, { primaryKey: data.id });

    const { Field, Subscribe, handleSubmit } = useForm<Employee, ZodValidator>({
        defaultValues: data,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => mutate(value),
    });

    const { mutate } = useUpdateEmployee({
        onSuccess: () => snackbar({ title: `${data.firstName} ${data.lastName} ${t("Updated").toLowerCase()}` }),
    });

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    const { mutate: deleteEmployee } = useDeleteEmployee({
        onSuccess: () => {
            navigate("/employees");
            snackbar({ title: `${data.firstName} ${data.lastName} ${t("Deleted").toLowerCase()}` });
        }
    });

    const handleDeleteEmployee = () => {
        if (window.confirm(t("AreYouSureToDelete")))
            deleteEmployee(data.id);
    };

    return (
        <VerticalTabs value={tab} onChange={setTab} tabs={[
            <Tab icon={<Person />} label={t("Employee")} value={0} key={0} />,
            <Tab icon={<Laptop />} label={t("Materials")} value={1} key={1} />,
            <Tab icon={<SwapHoriz />} label={t("Transfers")} value={2} key={2} />,
            <Tab icon={<History />} label={t("Logs")} value={3} key={3} disabled={!isAdmin} />
        ]}>
            <TabPanel value={tab} index={0}>
                <Container sx={{ my: 2 }}>
                    <Card variant="outlined">
                        <CardHeader title={`${data.firstName} ${data.lastName}`} subheader={data.id} action={<Chip label={data.status} color={data.status === EmployeeStatus.Active ? "success" : "error"} />} />
                        <form onSubmit={handleOnSubmit}>
                            <CardContent>
                                <Grid2 container spacing={2}>
                                    <Grid2 size={12}>
                                        <Field name="firstName" validators={{ onChange: z.string().min(1) }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label={t("FirstName")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required slotProps={{ input: { readOnly: !isAdmin } }} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Field name="lastName" validators={{ onChange: z.string().min(1) }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label={t("LastName")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required slotProps={{ input: { readOnly: !isAdmin } }} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Field name="id" validators={{ onChange: z.string().min(1) }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label="ID" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required slotProps={{ input: { readOnly: !isAdmin } }} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Field name="department" validators={{ onChange: z.string().min(1) }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value}
                                                    options={peoplesoftDepartments}
                                                    autoHighlight
                                                    onChange={(_, value) => handleChange(value!)}
                                                    readOnly={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Department")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Field name="company" validators={{ onChange: z.string().min(1) }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value}
                                                    options={["WCZ", "WSCZ"]}
                                                    autoHighlight
                                                    onChange={(_, value) => handleChange(value)}
                                                    readOnly={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Company")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                </Grid2>
                            </CardContent>

                            {isAdmin &&
                                <CardActions sx={{ justifyContent: "end" }}>
                                    <Button color="error" onClick={handleDeleteEmployee}>{t("Delete")}</Button>
                                    <Subscribe selector={(state) => [state.canSubmit]}>
                                        {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                                    </Subscribe>
                                </CardActions>
                            }
                        </form>
                    </Card>
                </Container>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <TypographyWithIcon startIcon={<Person />} variant="h6" sx={{ p: 2 }}>{`${data.firstName} ${data.lastName}`}</TypographyWithIcon>
                <TableContainer sx={{ height: { xs: "calc(100vh - 120px)", sm: "calc(100vh - 128px)" } }}>
                    <MaterialDataGrid data={materials} isFetching={isFetchingMaterials} />
                </TableContainer>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <TypographyWithIcon startIcon={<Person />} variant="h6" sx={{ p: 2 }}>{`${data.firstName} ${data.lastName}`}</TypographyWithIcon>
                <TableContainer sx={{ height: { xs: "calc(100vh - 120px)", sm: "calc(100vh - 128px)" } }}>
                    <TransferDataGrid data={materialTransferSearch.data} isFetching={isFetchingMaterialTransfer} />
                </TableContainer>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <TypographyWithIcon startIcon={<Person />} variant="h6" sx={{ p: 2 }}>{`${data.firstName} ${data.lastName}`}</TypographyWithIcon>
                <TableContainer sx={{ height: { xs: "calc(100vh - 120px)", sm: "calc(100vh - 128px)" } }}>
                    <TrailDataGrid data={trails} isFetching={isFetchingTrails} />
                </TableContainer>
            </TabPanel>
        </VerticalTabs>
    );
};