import { Group, Home, Inventory2, Laptop, LocationOn, History, Category, ShoppingCart, Person } from "@mui/icons-material";
import { darken, lighten } from "@mui/material";
import { indigo, purple } from "@mui/material/colors";
import { LayoutPaletteColorOptions, LayoutProvider, LayoutRoute, GetRoutesParams, hasRole } from "wcz-layout";
import packageJson from "../package.json";
import { HomePage } from "./pages/HomePage";
import { LayoutLocalizationProvider } from "./providers/LayoutLocalizationProvider";
import { MaterialsPage } from "./pages/materials/MaterialsPage";
import { LocationsPage } from "./pages/locations/LocationsPage";
import { EmployeesPage } from "./pages/employees/EmployeesPage";
import { CycleCountsPage } from "./pages/cycleCounts/CycleCountsPage";
import { LogsPage } from "./pages/LogsPage";
import { LocationDetailPage } from "./pages/locations/LocationDetailPage";
import { EmployeeDetailPage } from "./pages/employees/EmployeeDetailPage";
import { MaterialDetailPage } from "./pages/materials/MaterialDetailPage";
import { MaterialTypesPage } from "./pages/materials/MaterialTypesPage";
import { CreateLocationPage } from "./pages/locations/CreateLocationPage";
import { CreateEmployeePage } from "./pages/employees/CreateEmployeePage";
import { CreateMaterialPage } from "./pages/materials/CreateMaterialPage";
import { CartPage } from "./pages/CartPage";
import { FileProvider } from "wcz-file";
import { fileUrl } from "./utils/BaseUrl";
import AuthPolicy from "./utils/AuthPolicy";
import { MePage } from "./pages/MePage";
import CycleCountDetailPage from "./pages/cycleCounts/CycleCountDetailPage";
import { CreateCycleCountPage } from "./pages/cycleCounts/CreateCycleCountPage";
import { CreateUserCycleCountPage } from "./pages/cycleCounts/CreateUserCycleCountPage";

export const App: React.FC = () => {

    const getRoutes = ({ t }: GetRoutesParams): LayoutRoute[] => [
        {
            element: <LayoutLocalizationProvider />, children: [
                {
                    element: <FileProvider fileUrl={fileUrl} appName="it-eqroom.wistron.eu" />,
                    children: [
                        { path: "/", element: <HomePage />, icon: <Home fontSize="large" />, title: t("Layout.Home"), pageTitle: "IT Equipment Room", showInMenu: true, },
                        { path: "/locations", element: <LocationsPage />, icon: <LocationOn fontSize="large" />, title: t("Locations"), showInMenu: true, disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/locations/create", element: <CreateLocationPage />, title: t("CreateLocation"), disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/locations/:id", element: <LocationDetailPage />, title: t("Location"), },
                        { path: "/materials", element: <MaterialsPage />, icon: <Laptop fontSize="large" />, title: t("Materials"), showInMenu: true, disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/materials/types", element: <MaterialTypesPage />, icon: <Category fontSize="large" />, title: t("MaterialTypes"), showInMenu: true, disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/materials/create", element: <CreateMaterialPage />, title: t("CreateMaterial"), disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/materials/:id", element: <MaterialDetailPage />, title: t("Material"), },
                        { path: "/employees", element: <EmployeesPage />, icon: <Group fontSize="large" />, title: t("Employees"), showInMenu: true, disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/employees/create", element: <CreateEmployeePage />, title: t("CreateEmployee"), disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/employees/:id", element: <EmployeeDetailPage />, title: t("Employee"), },
                        { path: "/cart", element: <CartPage />, icon: <ShoppingCart fontSize="large" />, title: t("Cart"), showInMenu: true, disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/cycle-counts", element: <CycleCountsPage />, icon: <Inventory2 fontSize="large" />, title: t("CycleCounts"), showInMenu: true, },
                        { path: "/cycle-counts/create", element: <CreateCycleCountPage />, title: t("CreateCycleCount"), disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/cycle-counts/:id", element: <CycleCountDetailPage />, title: t("CycleCount"), disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/user-cycle-counts/create", element: <CreateUserCycleCountPage />, title: t("CreateCycleCount"), },
                        { path: "/logs", element: <LogsPage />, icon: <History fontSize="large" />, title: t("Logs"), showInMenu: true, disabled: !hasRole(AuthPolicy.Admin), },
                        { path: "/me", element: <MePage />, icon: <Person fontSize="large" />, title: t("Me"), },
                    ]
                }
            ]
        }
    ];

    const colors: LayoutPaletteColorOptions = {
        primary: {
            main: indigo[500],
            light: darken(indigo[500], 0.1),
            dark: lighten(indigo[500], 0.1),
        },
        secondary: {
            main: purple[500],
            light: darken(purple[500], 0.1),
            dark: lighten(purple[500], 0.1),
        }
    };

    return <LayoutProvider getRoutes={getRoutes} colors={colors} appVersion={packageJson.version} />;
};