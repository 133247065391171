import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridToolbar, LayoutContext } from "wcz-layout";
import { MaterialState } from "../../models/enums/MaterialState";
import Location from "../../models/Location";
import Material from "../../models/Material";
import { MaterialIcon } from "../../pages/materials/MaterialIcon";
import { keeperGetter, warrantyDateGetter } from "../../utils/Helpers";
import { MaterialBatchReceiveButton } from "./MaterialBatchReceiveButton";
import { MaterialBatchRelocationButton } from "./MaterialBatchRelocationButton";

interface MaterialDataGridProps {
    data: Material[];
    isFetching: boolean;
}

export const MaterialDataGrid: React.FC<MaterialDataGridProps> = ({ data, isFetching }) => {
    const { t } = useContext(LayoutContext);
    const navigate = useNavigate();
    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    const handleOnKeeperClick = (employeeId: string | undefined) => () => employeeId && navigate(`/employees/${employeeId}`);
    const handleOnLocationClick = (locationId: string | undefined) => () => locationId && navigate(`/locations/${locationId}`);

    const handleOnRowDoubleClick = (params: GridRowParams) => navigate(`/materials/${params.id}`);

    const columns: GridColDef<Material>[] = [
        {
            field: "actions", type: "actions", width: 50, getActions: ({ id, row }) => [
                <GridActionsCellItem key="material" label={t("Material")} icon={<MaterialIcon material={row} color="inherit" />} onClick={() => navigate(`/materials/${id}`)} />,
            ],
        },
        { field: "company", headerName: t("Company"), width: 120, },
        {
            field: "keeper", headerName: t("Keeper"), width: 250, valueGetter: keeperGetter, renderCell: ({ value, row }) =>
                <Typography
                    variant="body2"
                    onClick={handleOnKeeperClick(row.keeper?.id)}
                    sx={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
                >
                    {value}
                </Typography>
        },
        { field: "type", headerName: t("Type"), width: 150 },
        { field: "manufacturer", headerName: t("Manufacturer"), width: 150, },
        { field: "model", headerName: "Model", width: 220, },
        { field: "hostname", headerName: t("Hostname"), width: 200, },
        { field: "serialNumber", headerName: "SN", width: 170, },
        { field: "fixAsset", headerName: "Fix Asset", width: 160, },
        { field: "imei", headerName: "IMEI", width: 170, },
        {
            field: "location", headerName: t("Location"), width: 220, valueGetter: (value: Location) => value?.name,
            renderCell: ({ value, row }) =>
                <Typography
                    variant="body2"
                    onClick={handleOnLocationClick(row.location?.id)}
                    sx={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
                >
                    {value}
                </Typography>
        },
        { field: "department", headerName: t("Department"), width: 120, },
        { field: "partNumber", headerName: "PN", width: 170, },
        { field: "orderDate", headerName: t("OrderDate"), width: 150, type: "date", valueGetter: value => value && new Date(value), },
        { field: "monthsOfWarranty", headerName: t("Warranty"), type: "date", valueGetter: warrantyDateGetter },
        { field: "state", headerName: t("State"), width: 150, type: "singleSelect", valueOptions: Object.values(MaterialState) },
        { field: "underRepairTo", headerName: t("UnderRepairTo"), width: 150, type: "date", valueGetter: value => value && new Date(value), },
        { field: "price", headerName: t("Price"), width: 120, type: "number" },
        { field: "currency", headerName: t("Currency"), width: 120, },
        { field: "itId", headerName: "IT ID", width: 150 },
        { field: "stockQuantity", headerName: t("StockQuantity"), width: 190, type: "number" },
        { field: "safetyStock", headerName: t("SafetyStock"), width: 190, type: "number" },
        { field: "remark", headerName: t("Remark"), width: 350, },
        { field: "ip", headerName: "IP", width: 150, },
        { field: "macLan", headerName: "MAC LAN", width: 250, },
        { field: "macWlan", headerName: "MAC WLAN", width: 250, },
    ];

    return (
        <DataGridPremium
            rows={data}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                toolbar: {
                    actions: [
                        <Button key="checkboxSelection" startIcon={checkboxSelection ? <CheckBox /> : <CheckBoxOutlineBlank />} onClick={() => setCheckboxSelection(!checkboxSelection)}>{t("Selection")}</Button>,
                        <MaterialBatchReceiveButton key="batchReceiveButton" rowSelectionModel={rowSelectionModel} data={data.filter(material => material.state === MaterialState.InUse)} />,
                        <MaterialBatchRelocationButton key="batchRelocationButton" rowSelectionModel={rowSelectionModel} data={data} />
                    ]
                }
            }}
            onRowDoubleClick={handleOnRowDoubleClick}
            loading={isFetching}
            ignoreDiacritics
            checkboxSelection={checkboxSelection}
            onRowSelectionModelChange={(newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel)}
        />
    );
};