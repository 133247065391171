import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import Trail from "../../models/Trail";
import { TrailValueList } from "./TrailValueList";
import { GridToolbar, useLayout } from "wcz-layout";

interface TrailDataGridProps {
    data: Trail[];
    isFetching: boolean;
}

export const TrailDataGrid: React.FC<TrailDataGridProps> = ({ data, isFetching }) => {
    const { t } = useLayout();

    const columns: GridColDef<Trail>[] = [
        { field: "dateTime", headerName: t("Date"), width: 200, type: "dateTime", valueGetter: value => value && new Date(value) },
        { field: "userName", headerName: t("UpdatedBy"), width: 150, },
        { field: "userId", headerName: "ID", width: 150, },
        { field: "type", headerName: t("Type"), width: 150, },
        {
            field: "oldValues", headerName: t("OldValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
        {
            field: "newValues", headerName: t("NewValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
    ];

    return (
        <DataGridPremium
            rows={data}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            loading={isFetching}
            ignoreDiacritics
            getRowHeight={() => "auto"}
        />
    );
};