import { Autocomplete, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Container, FormControlLabel, FormGroup, Grid2, TextField } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { ZodValidator, zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, useContext } from "react";
import { LayoutContext, newGuid } from "wcz-layout";
import { z } from "zod";
import Location, { initLocation } from "../../models/Location";
import { useCreateLocation, useGetLocationOptions } from "../../queries/LocationQueries";
import { useNavigate } from "react-router-dom";

export const CreateLocationPage: React.FC = () => {
    const { t, snackbar } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data: options } = useGetLocationOptions();

    const { Field, Subscribe, handleSubmit, useStore } = useForm<Location, ZodValidator>({
        defaultValues: initLocation,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => mutate({
            ...value,
            id: newGuid(),
            name: value.isWarehouse ? `${value.building}${value.equipmentRoom}${value.rack}${value.shelf}${value.box ?? ""}` : value.name,
        }),
    });

    const store = useStore(state => state.values);

    const { mutate } = useCreateLocation({
        onSuccess: (data) => {
            snackbar({ title: `${data.name} ${t("Created").toLowerCase()}` });
            navigate(`/locations/${data.id}`);
        },
    });

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    return (
        <Container sx={{ my: 2 }}>
            <Card variant="outlined">
                <CardHeader title={t("CreateLocation")} />
                <form onSubmit={handleOnSubmit}>
                    <CardContent>
                        <Grid2 container spacing={2}>
                            <Grid2 size={12}>
                                <Field name="isWarehouse">
                                    {({ name, state, handleChange, handleBlur, form }) =>
                                        <FormGroup>
                                            <FormControlLabel name={name} control={<Checkbox checked={state.value} onBlur={handleBlur} />} label={t("IsWarehouse")} onChange={(e, checked) => {
                                                handleChange(checked);
                                                if (checked) {
                                                    form.setFieldValue("name", "");
                                                } else {
                                                    form.setFieldValue("building", null);
                                                    form.setFieldValue("equipmentRoom", null);
                                                    form.setFieldValue("rack", null);
                                                    form.setFieldValue("shelf", null);
                                                    form.setFieldValue("box", null);
                                                }
                                            }} />
                                        </FormGroup>
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12} display={store.isWarehouse ? "none" : undefined}>
                                <Field name="name" validators={{
                                    onChange: ({ value, fieldApi }) => {
                                        if (!fieldApi.form.getFieldValue("isWarehouse")) {
                                            const { success, error } = z.string().min(1).safeParse(value);
                                            if (!success) return error.issues[0].message;
                                        }
                                    },
                                    onChangeListenTo: ["isWarehouse"]
                                }}>
                                    {({ name, state, handleChange, handleBlur, form }) =>
                                        <TextField name={name} value={state.value} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                            label={t("Name")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={form.getFieldValue("isWarehouse")} required={!form.getFieldValue("isWarehouse")} />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                <Field name="building" validators={{
                                    onChange: ({ value, fieldApi }) => {
                                        if (fieldApi.form.getFieldValue("isWarehouse")) {
                                            const { success, error } = z.string().length(2).safeParse(value);
                                            if (!success) return error.issues[0].message;
                                        }
                                    }
                                }}>
                                    {({ name, state, handleChange, handleBlur, form }) =>
                                        <Autocomplete
                                            value={state.value ?? ""}
                                            options={options.buildings}
                                            autoHighlight
                                            freeSolo
                                            onInputChange={(_, value) => handleChange(value!)}
                                            renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Building")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("isWarehouse")} />}
                                        />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                <Field name="equipmentRoom" validators={{
                                    onChange: ({ value, fieldApi }) => {
                                        if (fieldApi.form.getFieldValue("isWarehouse")) {
                                            const { success, error } = z.string().length(2).safeParse(value);
                                            if (!success) return error.issues[0].message;
                                        }
                                    }
                                }}>
                                    {({ name, state, handleChange, handleBlur, form }) =>
                                        <Autocomplete
                                            value={state.value ?? ""}
                                            options={options.equipmentRooms}
                                            autoHighlight
                                            freeSolo
                                            onInputChange={(_, value) => handleChange(value!)}
                                            renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("EquipmentRoom")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("isWarehouse")} />}
                                        />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                <Field name="rack" validators={{
                                    onChange: ({ value, fieldApi }) => {
                                        if (fieldApi.form.getFieldValue("isWarehouse")) {
                                            const { success, error } = z.string().length(3).safeParse(value);
                                            if (!success) return error.issues[0].message;
                                        }
                                    }
                                }}>
                                    {({ name, state, handleChange, handleBlur, form }) =>
                                        <Autocomplete
                                            value={state.value ?? ""}
                                            options={options.racks}
                                            autoHighlight
                                            freeSolo
                                            onInputChange={(_, value) => handleChange(value)}
                                            renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Rack")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("isWarehouse")} />}
                                        />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                <Field name="shelf" validators={{
                                    onChange: ({ value, fieldApi }) => {
                                        if (fieldApi.form.getFieldValue("isWarehouse")) {
                                            const { success, error } = z.string().length(2).safeParse(value);
                                            if (!success) return error.issues[0].message;
                                        }
                                    }
                                }}>
                                    {({ name, state, handleChange, handleBlur, form }) =>
                                        <Autocomplete
                                            value={state.value ?? ""}
                                            options={options.shelfs}
                                            autoHighlight
                                            freeSolo
                                            onInputChange={(_, value) => handleChange(value)}
                                            renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Shelf")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("isWarehouse")} />}
                                        />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                <Field name="box" validators={{ onChange: z.string().length(4).nullable() }}>
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <Autocomplete
                                            value={state.value ?? ""}
                                            options={options.boxes}
                                            autoHighlight
                                            freeSolo
                                            onInputChange={(_, value) => handleChange(value)}
                                            renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Box")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                        />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12}>
                                <Field name="remark" validators={{ onChange: z.string().max(255).nullable() }}>
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                            label={t("Remark")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} multiline rows={3} />
                                    }
                                </Field>
                            </Grid2>
                        </Grid2>
                    </CardContent>

                    <CardActions sx={{ justifyContent: "end" }}>
                        <Subscribe selector={(state) => [state.canSubmit]}>
                            {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                        </Subscribe>
                    </CardActions>
                </form>
            </Card>
        </Container>
    );
};