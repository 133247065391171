import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GridToolbar, LayoutContext, TableContainer } from "wcz-layout";
import Employee from "../../models/Employee";
import { useGetEmployees } from "../../queries/EmployeeQueries";
import { EmployeeStatus } from "../../models/enums/EmployeeStatus";
import { Person } from "@mui/icons-material";

export const EmployeesPage: React.FC = () => {
    const { t } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data, isFetching } = useGetEmployees();

    const handleOnRowDoubleClick = (params: GridRowParams) => navigate(`/employees/${params.id}`);

    const columns: GridColDef<Employee>[] = [
        {
            field: "actions", type: "actions", width: 50, getActions: ({ id }) => [
                <GridActionsCellItem key="material" label={t("Material")} icon={<Person />} onClick={() => navigate(`/employees/${id}`)} />,
            ],
        },
        { field: "firstName", headerName: t("FirstName"), width: 130, },
        { field: "lastName", headerName: t("LastName"), width: 130, },
        { field: "id", headerName: "ID", width: 150, },
        { field: "department", headerName: t("Department"), width: 150, },
        { field: "company", headerName: t("Company"), width: 150, type: "singleSelect", valueOptions: ["WCZ", "WSCZ"] },
        { field: "status", headerName: "Status", width: 150, type: "singleSelect", valueOptions: Object.keys(EmployeeStatus) },
    ];

    return (
        <TableContainer>
            <DataGridPremium
                rows={data}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                onRowDoubleClick={handleOnRowDoubleClick}
                loading={isFetching}
                ignoreDiacritics
            />
        </TableContainer>
    );
};