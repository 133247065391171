import { LocationOn } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLayout } from "wcz-layout";
import Location from "../../../models/Location";
import { grey } from "@mui/material/colors";

interface SearchLocationCardProps {
    location: Location;
}

export const SearchLocationCard: React.FC<SearchLocationCardProps> = ({ location }) => {
    const { t } = useLayout();
    const navigate = useNavigate();

    const hasAdditionalInfo = location.building || location.equipmentRoom || location.rack || location.shelf || location.box || location.remark;

    return (
        <Card variant="outlined">
            <CardActionArea onClick={() => navigate(`/locations/${location.id}`)} sx={theme => ({ bgcolor: grey[theme.palette.mode === "dark" ? 900 : 100] })}>
                <CardHeader title={location.name} subheader={location.isWarehouse ? t("Warehouse") : t("OutsideWarehouse")} avatar={<LocationOn color={location.isWarehouse ? "primary" : "secondary"} />} />
            </CardActionArea>
            {hasAdditionalInfo &&
                <CardContent>
                    {location.building && <Typography variant="body2"><b>{t("Building")}:</b> {location.building}</Typography>}
                    {location.equipmentRoom && <Typography variant="body2"><b>{t("QquipmentRoom")}:</b> {location.equipmentRoom}</Typography>}
                    {location.rack && <Typography variant="body2"><b>{t("Rack")}:</b> {location.rack}</Typography>}
                    {location.shelf && <Typography variant="body2"><b>{t("Shelf")}:</b> {location.shelf}</Typography>}
                    {location.box && <Typography variant="body2"><b>{t("Box")}:</b> {location.box}</Typography>}
                    {location.remark && <Typography variant="body2"><b>{t("Remark")}:</b> {location.remark}</Typography>}
                </CardContent>
            }
        </Card>
    );
};