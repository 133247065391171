import { EditLocationAlt } from "@mui/icons-material";
import { Autocomplete, Button, Menu, Stack, TextField, Typography } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useForm } from "@tanstack/react-form";
import { ZodValidator, zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, Fragment, useEffect, useRef, useState } from "react";
import { hasRole, Platform, useLayout } from "wcz-layout";
import { z } from "zod";
import Location from "../../models/Location";
import Material from "../../models/Material";
import { useGetLocations } from "../../queries/LocationQueries";
import { useUpdateMaterial } from "../../queries/MaterialQueries";
import AuthPolicy from "../../utils/AuthPolicy";

interface MaterialBatchRelocationButtonForm {
    location: Location | null;
}

interface MaterialBatchRelocationButtonProps {
    rowSelectionModel: GridRowSelectionModel;
    data: Material[];
}

export const MaterialBatchRelocationButton: React.FC<MaterialBatchRelocationButtonProps> = ({ rowSelectionModel, data }) => {
    const { t, snackbar } = useLayout();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const locationAutocompleteRef = useRef<HTMLInputElement>(null);

    const { data: locations } = useGetLocations({ enabled: open });

    const { Field, Subscribe, handleSubmit, reset } = useForm<MaterialBatchRelocationButtonForm, ZodValidator>({
        defaultValues: { location: null },
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => updateMaterials(value),
    });

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    const { mutateAsync: updateMaterial } = useUpdateMaterial();

    const updateMaterials = (value: MaterialBatchRelocationButtonForm) => {
        const materials = data.filter((material) => rowSelectionModel.includes(material.id));
        const updatedMaterials = materials.map(material => updateMaterial({ ...material, location: value.location, }));

        Promise.all(updatedMaterials).then(() => {
            handleClose();
            reset();
            snackbar({ title: t("MaterialsHaveBeenRelocatedSuccessfully"), severity: "success" });
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => { setAnchorEl(null); reset(); };

    useEffect(() => {
        if (open && Platform.isWindows)
            setTimeout(() => locationAutocompleteRef.current?.focus(), 100);
    }, [open]);

    return (
        <Fragment>
            {hasRole(AuthPolicy.Admin) && <Button startIcon={<EditLocationAlt />} sx={{ display: rowSelectionModel.length ? "inherit" : "none" }} onClick={handleClick}>{t("Relocation")}</Button>}

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{ paper: { sx: { overflow: "visible", mt: 1.5, }, }, }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Typography variant="h6" sx={{ px: 2 }}>{t("Relocation")}</Typography>
                <form onSubmit={handleOnSubmit}>
                    <Stack spacing={2} sx={{ p: 2, width: { xs: 300, lg: 350 } }}>
                        <Field name="location" validators={{ onChange: z.object({}).required() }}>
                            {({ name, state, handleChange, handleBlur }) =>
                                <Autocomplete
                                    value={state.value}
                                    options={locations}
                                    getOptionLabel={(option) => option.name}
                                    autoHighlight
                                    onChange={(_, value) => handleChange(value)}
                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Location")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]}
                                        required inputRef={locationAutocompleteRef} />}

                                />
                            }
                        </Field>

                        <Subscribe selector={(state) => [state.canSubmit]}>
                            {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                        </Subscribe>
                    </Stack>
                </form>
            </Menu>
        </Fragment>
    );
};