import { Laptop, Person, SwapHoriz } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Container, Tab, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";
import React, { useState } from "react";
import { TableContainer, TypographyWithIcon, useLayout } from "wcz-layout";
import { TabPanel } from "../components/common/TabPanel";
import { VerticalTabs } from "../components/common/VerticalTabs";
import { MaterialDataGrid } from "../components/material/MaterialDataGrid";
import { TransferDataGrid } from "../components/transfer/TransferDataGrid";
import PaginationFilter from "../models/base/PaginationFilter";
import { EmployeeStatus } from "../models/enums/EmployeeStatus";
import { useGetEmployee } from "../queries/EmployeeQueries";
import { useGetMaterials, useGetMaterialTransferSearch } from "../queries/MaterialQueries";

const materialTransferFilter = (employeeId: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "or",
            filters: [
                { field: "oldKeeper.id", operator: "eq", value: employeeId },
                { field: "newKeeper.id", operator: "eq", value: employeeId },
            ]
        },
        orderBy: ["dateTime desc"]
    };
};

export const MePage: React.FC = () => {
    const { t, user } = useLayout();
    const [tab, setTab] = useState(0);

    const { data: employee } = useGetEmployee(user.employeeId, {
        enabled: tab === 0 && !!user.employeeId,
        refetchOnWindowFocus: false,
    });

    const { data: materials, isFetching: isFetchingMaterials } = useGetMaterials({ enabled: !!employee.id && tab === 1 }, { employeeId: employee.id });

    const { data: materialTransferSearch, isFetching: isFetchingMaterialTransfer } = useGetMaterialTransferSearch(materialTransferFilter(employee.id), { enabled: !!employee.id && tab === 2 });

    return (
        <VerticalTabs value={tab} onChange={setTab} tabs={[
            <Tab icon={<Person />} label={t("Employee")} value={0} key={0} />,
            <Tab icon={<Laptop />} label={t("Materials")} value={1} key={1} />,
            <Tab icon={<SwapHoriz />} label={t("Transfers")} value={2} key={2} />
        ]}>
            <TabPanel value={tab} index={0}>
                <Container sx={{ my: 2 }}>
                    <Card variant="outlined">
                        <CardHeader
                            title={`${employee.firstName} ${employee.lastName} (${employee.id})`}
                            subheader={employee.status} avatar={<Person color={employee.status === EmployeeStatus.Active ? "success" : "error"} />}
                            sx={theme => ({ bgcolor: grey[theme.palette.mode === "dark" ? 900 : 100] })}
                        />
                        <CardContent>
                            <Typography variant="body2"><b>{t("Department")}:</b> {employee.department}</Typography>
                            {employee.company && <Typography variant="body2"><b>{t("Company")}:</b> {employee.company}</Typography>}
                            {employee.terminationFutureDate && <Typography variant="body2"><b>{t("TerminationDate")}:</b> {moment(employee.terminationFutureDate).formatDate()}</Typography>}
                        </CardContent>
                    </Card>
                </Container>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <TypographyWithIcon startIcon={<Person />} variant="h6" sx={{ p: 2 }}>{user.name}</TypographyWithIcon>
                <TableContainer sx={{ height: { xs: "calc(100vh - 120px)", sm: "calc(100vh - 128px)" } }}>
                    <MaterialDataGrid data={materials} isFetching={isFetchingMaterials} />
                </TableContainer>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <TypographyWithIcon startIcon={<Person />} variant="h6" sx={{ p: 2 }}>{user.name}</TypographyWithIcon>
                <TableContainer sx={{ height: { xs: "calc(100vh - 120px)", sm: "calc(100vh - 128px)" } }}>
                    <TransferDataGrid data={materialTransferSearch.data} isFetching={isFetchingMaterialTransfer} />
                </TableContainer>
            </TabPanel>
        </VerticalTabs>
    );
};