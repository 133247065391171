import { Close, ScreenRotation } from "@mui/icons-material";
import { AppBar, Backdrop, Button, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import { FC, useContext, useEffect, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { Upload as TusUpload } from "tus-js-client";
import { LayoutContext, getToken } from "wcz-layout";
import { fileUrl } from "../../utils/BaseUrl";

interface SignatureDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    handoverId: string,
    onSuccess: () => void
}

export const SignatureDialog: FC<SignatureDialogProps> = ({ open, setOpen, handoverId, onSuccess }) => {
    const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);
    const { user, t } = useContext(LayoutContext);
    const [isLandscape, setIsLandscape] = useState<boolean>(true);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const isMutating = !!useIsMutating();

    useEffect(() => {
        if (open) {
            handleOnOrientationChange();
            window.addEventListener("orientationchange", handleOnOrientationChange);
        }
        return () => window.removeEventListener("orientationchange", handleOnOrientationChange);
    }, [open]);

    const handleOnOrientationChange = () => {
        setTimeout(() => setIsLandscape(window.matchMedia("(orientation: landscape)").matches), 300);
    };

    const handleUpload = async (file: File) => {
        if (!handoverId) return;
        setUploadProgress(1);

        const upload = new TusUpload(file, {
            endpoint: `${fileUrl}/v1/upload`,
            chunkSize: 1048576,
            metadata: {
                appName: "it-eqroom.wistron.eu",
                subId: handoverId,
                filename: file.name,
                filetype: file.type
            },
            headers: { "Authorization": `Bearer ${await getToken()}` },
            onError: error => {
                console.error(error);
                setUploadProgress(0);
            },
            onSuccess: () => {
                onSuccess();
                setUploadProgress(0);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                setUploadProgress((bytesUploaded / bytesTotal) * 100);
            },
        });

        const previousUploads = await upload.findPreviousUploads();
        if (previousUploads.length) upload.resumeFromPreviousUpload(previousUploads[0]);
        upload.start();
    };

    const onSave = async () => {
        const dataUrl = signaturePad?.toDataURL("image/png");
        if (dataUrl) {
            const file = await urltoFile(dataUrl, `signature-${user.employeeId}.png`, "image/png");
            handleUpload(file);
        }
    };

    const urltoFile = (url: string, filename: string, type: string): Promise<File> => {
        return fetch(url)
            .then(res => res.arrayBuffer())
            .then(buf => new File([buf], filename, { type }));
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
            <AppBar color="default">
                <Toolbar>
                    <IconButton onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>
                    <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>{t("Signature")}</Typography>
                    <Button onClick={() => signaturePad?.clear()} sx={{ mr: 1 }} disabled={isMutating || !!uploadProgress}>{t("Clear")}</Button>
                    <Button color="primary" variant="contained" onClick={onSave} disabled={isMutating || !!uploadProgress}>{t("Submit")}</Button>
                </Toolbar>
            </AppBar>
            <SignaturePad ref={setSignaturePad} redrawOnResize />
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!isLandscape}>
                <Typography variant="h5" sx={{ display: "flex", alignItems: "center", px: 1 }}>
                    <ScreenRotation sx={{ mr: 1 }} />{t("PleaseUseLandscapeOrientation")}
                </Typography>
            </Backdrop>
        </Dialog>
    );
};