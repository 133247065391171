import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useLayout } from "wcz-layout";

export const LayoutLocalizationProvider: FC = () => {
    const { i18n } = useLayout();

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.resolvedLanguage}>
            <Outlet />
        </LocalizationProvider>
    );
};