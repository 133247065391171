import { DefinedInitialDataOptions, useMutation, UseMutationOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { fetchGet, fetchPost, LayoutContext, useLayout } from "wcz-layout";
import EmployeeSearchHistory from "../models/EmployeeSearchHistory";
import { baseUrl } from "../utils/BaseUrl";

const route: string = "v1/EmployeeSearchHistory";

export const useGetEmployeeSearchHistories = (options?: Omit<DefinedInitialDataOptions<EmployeeSearchHistory[], Error, EmployeeSearchHistory[]>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<EmployeeSearchHistory[], Error, EmployeeSearchHistory[]>({
        ...options,
        queryKey: [route],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useCreateEmployeeSearchHistory = (options?: Omit<UseMutationOptions<EmployeeSearchHistory, Error, EmployeeSearchHistory>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useLayout();
    const queryClient = useQueryClient();

    return useMutation<EmployeeSearchHistory, Error, EmployeeSearchHistory>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: EmployeeSearchHistory[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};