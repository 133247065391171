import Employee from "./Employee";
import Location from "./Location";
import Material from "./Material";

export default interface SearchModel {
    materials: Material[],
    locations: Location[],
    employees: Employee[],
    totalMaterials: number,
    totalLocations: number,
    totalEmployees: number,
}

export const initSearchModel: SearchModel = {
    materials: [],
    locations: [],
    employees: [],
    totalMaterials: 0,
    totalLocations: 0,
    totalEmployees: 0,
};