import { GridRowId } from "@mui/x-data-grid-premium";
import { DefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext, fetchGet, fetchPost, fetchPut, fetchDelete, Error } from "wcz-layout";
import { baseUrl } from "../utils/BaseUrl";
import Location, { initLocation } from "../models/Location";
import LocationOptions, { initLocationOptions } from "../models/LocationOptions";

const route: string = "v1/Location";

interface GetLocationsParams {
    isWarehouse?: boolean;
}

export const useGetLocations = (options?: Omit<DefinedInitialDataOptions<Location[], Error, Location[]>, "queryKey" | "queryFn" | "initialData">, params?: GetLocationsParams) => {
    const { snackbar } = useContext(LayoutContext);

    const queryParams = new URLSearchParams();
    if (params?.isWarehouse !== undefined) queryParams.append("isWarehouse", params.isWarehouse.toString());

    const query = useQuery<Location[], Error, Location[]>({
        ...options,
        queryKey: [route],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}?${queryParams.toString()}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetLocationOptions = (options?: Omit<DefinedInitialDataOptions<LocationOptions, Error, LocationOptions>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<LocationOptions, Error, LocationOptions>({
        ...options,
        queryKey: [route, "options"],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/options`, signal),
        initialData: initLocationOptions,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetLocation = (id: GridRowId, options?: Omit<DefinedInitialDataOptions<Location, Error, Location>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<Location, Error, Location>({
        ...options,
        queryKey: [route, id],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/${id}`, signal),
        initialData: initLocation,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useCreateLocation = (options?: Omit<UseMutationOptions<Location, Error, Location>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<Location, Error, Location>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Location[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useUpdateLocation = (options?: Omit<UseMutationOptions<Location, Error, Location>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<Location, Error, Location>({
        ...options,
        mutationFn: model => fetchPut(`${baseUrl}/${route}/${model.id}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Location[]) => old.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData([route, model.id]);
            if (previousData)
                queryClient.setQueryData([route, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);
            queryClient.setQueryData([route, context.previousData.employeeId], context.previousData);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useDeleteLocation = (options?: Omit<UseMutationOptions<GridRowId, Error, GridRowId>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<GridRowId, Error, GridRowId>({
        ...options,
        mutationFn: id => fetchDelete(`${baseUrl}/${route}/${id}`),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Location[]) => old.filter(prev => prev.id !== id));

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};