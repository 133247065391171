import { Person } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { useLayout } from "wcz-layout";
import { useGetEmployee } from "../../../queries/EmployeeQueries";
import moment from "moment";
import { EmployeeStatus } from "../../../models/enums/EmployeeStatus";
import { useGetMaterialOptions, useGetMaterials } from "../../../queries/MaterialQueries";
import { SearchMaterialCard } from "./SearchMaterialCard";

export const AboutMe: React.FC = () => {
    const { t, user } = useLayout();
    const navigate = useNavigate();

    const { data: employee } = useGetEmployee(user.employeeId, {
        enabled: !!user.employeeId,
    });

    const { data: materials } = useGetMaterials({ enabled: !!employee?.id }, { employeeId: employee?.id });

    const { data: materialOptions } = useGetMaterialOptions({ enabled: !!materials.length });

    return (
        <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography variant="h6">{t("Me")}</Typography>
            <Card variant="outlined">
                <CardActionArea onClick={() => navigate("/me")} sx={theme => ({ bgcolor: grey[theme.palette.mode === "dark" ? 900 : 100] })}>
                    <CardHeader title={`${employee.firstName} ${employee.lastName} (${employee.id})`} subheader={employee.status} avatar={<Person color={employee.status === EmployeeStatus.Active ? "success" : "error"} />} />
                </CardActionArea>
                <CardContent>
                    <Typography variant="body2"><b>{t("Department")}:</b> {employee.department}</Typography>
                    {employee.company && <Typography variant="body2"><b>{t("Company")}:</b> {employee.company}</Typography>}
                    {employee.terminationFutureDate && <Typography variant="body2"><b>{t("TerminationDate")}:</b> {moment(employee.terminationFutureDate).formatDate()}</Typography>}
                </CardContent>
            </Card>

            <Typography variant="h6">{t("Materials")} ({materials.length})</Typography>
            {materials.map(material => <SearchMaterialCard key={material.id} material={material} options={materialOptions} />)}
        </Stack>
    );
};