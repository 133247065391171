import { List, ListItem, Typography } from "@mui/material";

interface ValueListProps {
    object: any
}

export const TrailValueList: React.FC<ValueListProps> = ({ object }) => {
    return (
        <List dense>
            {Object.keys(object).map(key =>
                <ListItem key={key} disablePadding>
                    <Typography variant="body2"><b>{key}: </b> {String(object[key])}</Typography>
                </ListItem>
            )}
        </List>
    );
};