import { DefinedInitialDataInfiniteOptions, useInfiniteQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Error, LayoutContext, fetchPost } from "wcz-layout";
import SearchModel, { initSearchModel } from "../models/SearchModel";
import { baseUrl } from "../utils/BaseUrl";

const route: string = "v1/search";

interface GetSearchParams {
    value: string;
    take: number;
}

export const useGetSearch = (params: GetSearchParams, options: Omit<DefinedInitialDataInfiniteOptions<SearchModel, Error>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useInfiniteQuery<SearchModel, Error>({
        ...options,
        queryKey: [route, "infiniteSearch", params],
        queryFn: ({ pageParam }) => fetchPost(`${baseUrl}/${route}`, { value: params.value, take: params.take, page: pageParam }),
        initialData: { pages: [initSearchModel], pageParams: [] },
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};