import { GridRowId } from "@mui/x-data-grid-premium";
import { DefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext, fetchGet, fetchPost, fetchPut, fetchDelete, Error } from "wcz-layout";
import { baseUrl } from "../utils/BaseUrl";
import MaterialType, { initMaterialType } from "../models/MaterialType";

const route: string = "v1/MaterialType";

export const useGetMaterialTypes = (options?: Omit<DefinedInitialDataOptions<MaterialType[], Error, MaterialType[]>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<MaterialType[], Error, MaterialType[]>({
        ...options,
        queryKey: [route],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetMaterialType = (id: GridRowId, options?: Omit<DefinedInitialDataOptions<MaterialType, Error, MaterialType>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<MaterialType, Error, MaterialType>({
        ...options,
        queryKey: [route, id],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/${id}`, signal),
        initialData: initMaterialType,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useCreateMaterialType = (options?: Omit<UseMutationOptions<MaterialType, Error, MaterialType>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<MaterialType, Error, MaterialType>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: MaterialType[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useUpdateMaterialType = (options?: Omit<UseMutationOptions<MaterialType, Error, MaterialType>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<MaterialType, Error, MaterialType>({
        ...options,
        mutationFn: model => fetchPut(`${baseUrl}/${route}/${model.id}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: MaterialType[]) => old.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData([route, model.id]);
            if (previousData)
                queryClient.setQueryData([route, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);
            queryClient.setQueryData([route, context.previousData.MaterialTypeId], context.previousData);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useDeleteMaterialType = (options?: Omit<UseMutationOptions<GridRowId, Error, GridRowId>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<GridRowId, Error, GridRowId>({
        ...options,
        mutationFn: id => fetchDelete(`${baseUrl}/${route}/${id}`),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: MaterialType[]) => old.filter(prev => prev.id !== id));

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};