import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Drawer, Typography } from "@mui/material";
import { BarcodeScanner } from "dynamsoft-javascript-barcode";
import { FC, useCallback, useEffect, useRef } from "react";

let scanner: BarcodeScanner | null = null;

interface DynamsoftScannerProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onScan: (value: string) => void;
    disableAutoclose?: boolean;
}

export const DynamsoftScanner: FC<DynamsoftScannerProps> = ({ open, setOpen, disableAutoclose, onScan }) => {
    const elRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open) initScannerAsync();
    }, [open]);

    const initScannerAsync = async () => {
        scanner = await BarcodeScanner.createInstance();
        await scanner.setUIElement(elRef.current!);
        scanner.onUniqueRead = (txt) => { onScan(txt); !disableAutoclose && handleClose(); };
        await scanner.open();
    };

    const handleClose = useCallback(() => {
        setOpen(false);

        if (scanner)
            scanner.destroyContext();
    }, [scanner]);

    return (
        <Drawer anchor="bottom" open={open} onClose={handleClose}>
            <Typography sx={{ textAlign: "center", cursor: "pointer" }}>
                <KeyboardArrowDown />
            </Typography>
            <Box ref={elRef} sx={{ width: "100vw", height: { xs: "70vh" }, position: "relative" }} className="dce-video-container"></Box>
        </Drawer>
    );
};