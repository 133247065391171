import { GridRowId } from "@mui/x-data-grid-premium";
import { DefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Error, LayoutContext, fetchDelete, fetchGet, fetchPost, fetchPut } from "wcz-layout";
import PaginationFilter from "../models/base/PaginationFilter";
import PaginationResponse, { initialPaginationResponse } from "../models/base/PaginationResponse";
import Material, { initMaterial } from "../models/Material";
import MaterialOptions, { initMaterialOptions } from "../models/MaterialOptions";
import MaterialTransfer from "../models/MaterialTransfer";
import { baseUrl } from "../utils/BaseUrl";

const route: string = "v1/Material";

interface MaterialQueryParams {
    locationId?: string;
    employeeId?: string;
}

export const useGetMaterials = (options?: Omit<DefinedInitialDataOptions<Material[], Error, Material[]>, "queryKey" | "queryFn" | "initialData">, params?: MaterialQueryParams) => {
    const { snackbar } = useContext(LayoutContext);

    const queryParams = new URLSearchParams();
    if (params?.locationId) queryParams.append("locationId", params.locationId);
    if (params?.employeeId) queryParams.append("employeeId", params.employeeId);

    const query = useQuery<Material[], Error, Material[]>({
        ...options,
        queryKey: [route, queryParams],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}?${queryParams.toString()}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetMaterial = (id: GridRowId, options?: Omit<DefinedInitialDataOptions<Material, Error, Material>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<Material, Error, Material>({
        ...options,
        queryKey: [route, id],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/${id}`, signal),
        initialData: initMaterial,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetMaterialOptions = (options?: Omit<DefinedInitialDataOptions<MaterialOptions, Error, MaterialOptions>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<MaterialOptions, Error, MaterialOptions>({
        ...options,
        queryKey: [route, "options"],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/options`, signal),
        initialData: initMaterialOptions,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetMaterialSearch = (filter: PaginationFilter, options?: Omit<DefinedInitialDataOptions<PaginationResponse<Material>, Error, PaginationResponse<Material>>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<PaginationResponse<Material>, Error, PaginationResponse<Material>>({
        ...options,
        queryKey: [route, "search", filter],
        queryFn: () => fetchPost(`${baseUrl}/${route}/search`, filter),
        initialData: initialPaginationResponse,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetMaterialTransferSearch = (filter: PaginationFilter, options?: Omit<DefinedInitialDataOptions<PaginationResponse<MaterialTransfer>, Error, PaginationResponse<MaterialTransfer>>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<PaginationResponse<MaterialTransfer>, Error, PaginationResponse<MaterialTransfer>>({
        ...options,
        queryKey: [route, "transfer", "search", filter],
        queryFn: () => fetchPost(`${baseUrl}/${route}/transfer/search`, filter),
        initialData: initialPaginationResponse,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useCreateMaterial = (options?: Omit<UseMutationOptions<Material, Error, Material>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<Material, Error, Material>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Material[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useImportMaterial = (options?: Omit<UseMutationOptions<Material[], Error, FormData>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<Material[], Error, FormData>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}/import`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Material[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useUpdateMaterial = (options?: Omit<UseMutationOptions<Material, Error, Material>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<Material, Error, Material>({
        ...options,
        mutationFn: model => fetchPut(`${baseUrl}/${route}/${model.id}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Material[]) => old.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData([route, model.id]);
            if (previousData)
                queryClient.setQueryData([route, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);
            queryClient.setQueryData([route, context.previousData.id], context.previousData);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [route], exact: false });
            queryClient.invalidateQueries({ queryKey: ["v1/search"], exact: false });
            queryClient.invalidateQueries({ queryKey: ["v1/Cart"], exact: false });
        },
    });
};

export const useDeleteMaterial = (options?: Omit<UseMutationOptions<GridRowId, Error, GridRowId>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<GridRowId, Error, GridRowId>({
        ...options,
        mutationFn: id => fetchDelete(`${baseUrl}/${route}/${id}`),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Material[]) => old.filter(prev => prev.id !== id));

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};