import { ShoppingCartCheckout } from "@mui/icons-material";
import { Container, Fab, Stack, Tab } from "@mui/material";
import React, { useState } from "react";
import { useLayout } from "wcz-layout";
import { CartItemCard } from "../components/cart/CartItemCard";
import { TabPanel } from "../components/common/TabPanel";
import { VerticalTabs } from "../components/common/VerticalTabs";
import { useDeleteCart, useGetCarts } from "../queries/CartQueries";
import { useGetMaterialOptions } from "../queries/MaterialQueries";
import { SignatureDialog } from "../components/cart/SignatureDialog";
import { useCreateHandover } from "../queries/HandoverQueries";
import Cart from "../models/Cart";
import Handover from "../models/Handover";
import { useGetMaterialTypes } from "../queries/MaterialTypeQueries";
import { EmployeeStatus } from "../models/enums/EmployeeStatus";

const getHandover = (cart: Cart): Handover => {
    return {
        id: cart.id,
        items: [...cart.items],
        keeper: cart.employee!,
        type: cart.type,
        created: null
    };
};

export const CartPage: React.FC = () => {
    const { t, snackbar } = useLayout();
    const [tab, setTab] = useState(0);
    const [signatureOpen, setSignatureOpen] = useState(false);

    const { data: carts } = useGetCarts();
    const { data: options } = useGetMaterialOptions();
    const { data: materialTypes } = useGetMaterialTypes();

    const cart = carts[tab];

    const onCheckoutClick = () => {
        const isSignatureRequired = cart.items.some(item => materialTypes.some(type => type.requireSignature && type.name === item.material.type));
        isSignatureRequired ? setSignatureOpen(true) : createHandover(getHandover(cart));
    };

    const { mutate: deleteCart } = useDeleteCart();

    const { mutate: createHandover } = useCreateHandover({
        onSuccess: () => {
            setSignatureOpen(false);
            deleteCart(cart.id);
            snackbar({ title: t("Handovered"), severity: "success" });
        }
    });

    const onSignatureSuccess = () => {
        createHandover(getHandover(cart));
    };

    const disabled = !cart?.items || cart.employee?.status !== EmployeeStatus.Active || cart?.items?.some(item => materialTypes.some(type => type.requireLocation && type.name === item.material.type && !item.material.location));

    return (
        <VerticalTabs value={tab} onChange={setTab} tabs={carts.map((cart, index) =>
            <Tab label={`${cart.employee?.firstName} ${cart.employee?.lastName}`} value={index} key={index} />
        )}>
            {carts.map((cart, index) =>
                <TabPanel value={tab} index={index} key={cart.id}>
                    <Container sx={{ my: 2 }}>
                        <Stack spacing={2}>
                            {cart.items.map(item => <CartItemCard key={item.id} item={item} cart={carts[index]} tab={tab} setTab={setTab} options={options} />)}
                        </Stack>
                    </Container>
                </TabPanel>
            )}

            <Fab variant="extended" sx={{ position: "fixed", bottom: 16, left: "50%", transform: "translateX(-50%)" }} onClick={onCheckoutClick} disabled={disabled}>
                <ShoppingCartCheckout sx={{ mr: 1 }} />
                {`${t(cart?.type)} (${cart?.items.length})`}
            </Fab>

            <SignatureDialog open={signatureOpen} setOpen={setSignatureOpen} handoverId={cart?.id} onSuccess={onSignatureSuccess} />
        </VerticalTabs>
    );
};