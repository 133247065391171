import { History, Laptop, LocationOn } from "@mui/icons-material";
import { Autocomplete, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Container, FormControlLabel, FormGroup, Grid2, Tab, TextField } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { ZodValidator, zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { hasRole, LayoutContext, TableContainer, TypographyWithIcon } from "wcz-layout";
import { z } from "zod";
import { TabPanel } from "../../components/common/TabPanel";
import { VerticalTabs } from "../../components/common/VerticalTabs";
import { MaterialDataGrid } from "../../components/material/MaterialDataGrid";
import { TrailDataGrid } from "../../components/trail/TrailDataGrid";
import { useDeleteLocation, useGetLocation, useGetLocationOptions, useUpdateLocation } from "../../queries/LocationQueries";
import { useGetMaterials } from "../../queries/MaterialQueries";
import { useGetTrails } from "../../queries/TrailQueries";
import AuthPolicy from "../../utils/AuthPolicy";
import Location from "../../models/Location";

export const LocationDetailPage: React.FC = () => {
    const { id } = useParams();
    const { t, snackbar } = useContext(LayoutContext);
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();
    const isAdmin = hasRole(AuthPolicy.Admin);

    const { data } = useGetLocation(id!, {
        enabled: tab === 0,
        refetchOnWindowFocus: false,
    });

    const { data: options } = useGetLocationOptions({ enabled: tab === 0, });

    const { data: materials, isFetching: isFetchingMaterials } = useGetMaterials({ enabled: !!data.id && tab === 1 }, { locationId: data.id });

    const { data: trails, isFetching: isFetchingTrails } = useGetTrails({ enabled: !!data.id && tab === 2 }, { primaryKey: data.id });

    const { Field, Subscribe, handleSubmit, useStore } = useForm<Location, ZodValidator>({
        defaultValues: data,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => mutate({
            ...value,
            name: value.isWarehouse ? `${value.building}${value.equipmentRoom}${value.rack}${value.shelf}${value.box ?? ""}` : value.name,
        }),
    });

    const store = useStore(state => state.values);

    const { mutate } = useUpdateLocation({
        onSuccess: () => snackbar({ title: `${data.name} ${t("Updated").toLowerCase()}` }),
    });

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    const { mutate: deleteLocation } = useDeleteLocation({
        onSuccess: () => {
            navigate("/locations");
            snackbar({ title: `${data.name} ${t("Deleted").toLowerCase()}` });
        }
    });

    const handleDeleteLocation = () => {
        if (window.confirm(t("AreYouSureToDelete")))
            deleteLocation(data.id);
    };

    return (
        <VerticalTabs value={tab} onChange={setTab} tabs={[
            <Tab icon={<LocationOn />} label={t("Location")} value={0} key={0} />,
            <Tab icon={<Laptop />} label={t("Materials")} value={1} key={1} />,
            <Tab icon={<History />} label={t("Logs")} value={2} key={2} disabled={!isAdmin} />
        ]}>
            <TabPanel value={tab} index={0}>
                <Container sx={{ my: 2 }}>
                    <Card variant="outlined">
                        <CardHeader title={data.name} />
                        <form onSubmit={handleOnSubmit}>
                            <CardContent>
                                <Grid2 container spacing={2}>
                                    <Grid2 size={12}>
                                        <Field name="isWarehouse">
                                            {({ name, state, handleChange, handleBlur, form }) =>
                                                <FormGroup>
                                                    <FormControlLabel name={name} control={<Checkbox checked={state.value} onBlur={handleBlur} readOnly={!isAdmin} />} label={t("IsWarehouse")} onChange={(e, checked) => {
                                                        handleChange(checked);
                                                        if (checked) {
                                                            form.setFieldValue("name", "");
                                                        } else {
                                                            form.setFieldValue("building", null);
                                                            form.setFieldValue("equipmentRoom", null);
                                                            form.setFieldValue("rack", null);
                                                            form.setFieldValue("shelf", null);
                                                            form.setFieldValue("box", null);
                                                        }
                                                    }} />
                                                </FormGroup>
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={store.isWarehouse ? "none" : undefined}>
                                        <Field name="name" validators={{
                                            onChange: ({ value, fieldApi }) => {
                                                if (!fieldApi.form.getFieldValue("isWarehouse")) {
                                                    const { success, error } = z.string().min(1).safeParse(value);
                                                    if (!success) return error.issues[0].message;
                                                }
                                            },
                                            onChangeListenTo: ["isWarehouse"]
                                        }}>
                                            {({ name, state, handleChange, handleBlur, form }) =>
                                                <TextField name={name} value={state.value} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label={t("Name")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={form.getFieldValue("isWarehouse")} required={!form.getFieldValue("isWarehouse")} slotProps={{ input: { readOnly: !isAdmin } }} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                        <Field name="building" validators={{
                                            onChange: ({ value, fieldApi }) => {
                                                if (fieldApi.form.getFieldValue("isWarehouse")) {
                                                    const { success, error } = z.string().length(2).safeParse(value);
                                                    if (!success) return error.issues[0].message;
                                                }
                                            }
                                        }}>
                                            {({ name, state, handleChange, handleBlur, form }) =>
                                                <Autocomplete
                                                    value={state.value ?? ""}
                                                    options={options.buildings}
                                                    autoHighlight
                                                    freeSolo
                                                    onInputChange={(_, value) => handleChange(value!)}
                                                    readOnly={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Building")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("isWarehouse")} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                        <Field name="equipmentRoom" validators={{
                                            onChange: ({ value, fieldApi }) => {
                                                if (fieldApi.form.getFieldValue("isWarehouse")) {
                                                    const { success, error } = z.string().length(2).safeParse(value);
                                                    if (!success) return error.issues[0].message;
                                                }
                                            }
                                        }}>
                                            {({ name, state, handleChange, handleBlur, form }) =>
                                                <Autocomplete
                                                    value={state.value ?? ""}
                                                    options={options.equipmentRooms}
                                                    autoHighlight
                                                    freeSolo
                                                    onInputChange={(_, value) => handleChange(value!)}
                                                    readOnly={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("EquipmentRoom")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("isWarehouse")} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                        <Field name="rack" validators={{
                                            onChange: ({ value, fieldApi }) => {
                                                if (fieldApi.form.getFieldValue("isWarehouse")) {
                                                    const { success, error } = z.string().length(3).safeParse(value);
                                                    if (!success) return error.issues[0].message;
                                                }
                                            }
                                        }}>
                                            {({ name, state, handleChange, handleBlur, form }) =>
                                                <Autocomplete
                                                    value={state.value ?? ""}
                                                    options={options.racks}
                                                    autoHighlight
                                                    freeSolo
                                                    onInputChange={(_, value) => handleChange(value)}
                                                    readOnly={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Rack")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("isWarehouse")} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                        <Field name="shelf" validators={{
                                            onChange: ({ value, fieldApi }) => {
                                                if (fieldApi.form.getFieldValue("isWarehouse")) {
                                                    const { success, error } = z.string().length(2).safeParse(value);
                                                    if (!success) return error.issues[0].message;
                                                }
                                            }
                                        }}>
                                            {({ name, state, handleChange, handleBlur, form }) =>
                                                <Autocomplete
                                                    value={state.value ?? ""}
                                                    options={options.shelfs}
                                                    autoHighlight
                                                    freeSolo
                                                    onInputChange={(_, value) => handleChange(value)}
                                                    readOnly={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Shelf")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("isWarehouse")} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!store.isWarehouse ? "none" : undefined}>
                                        <Field name="box" validators={{ onChange: z.string().length(4).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value ?? ""}
                                                    options={options.boxes}
                                                    autoHighlight
                                                    freeSolo
                                                    onInputChange={(_, value) => handleChange(value)}
                                                    readOnly={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Box")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Field name="remark" validators={{ onChange: z.string().max(255).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label={t("Remark")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} multiline rows={3} slotProps={{ input: { readOnly: !isAdmin } }} />
                                            }
                                        </Field>
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                            {isAdmin &&
                                <CardActions sx={{ justifyContent: "end" }}>
                                    <Button color="error" onClick={handleDeleteLocation}>{t("Delete")}</Button>
                                    <Subscribe selector={(state) => [state.canSubmit]}>
                                        {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                                    </Subscribe>
                                </CardActions>
                            }
                        </form>
                    </Card>
                </Container>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <TypographyWithIcon startIcon={<LocationOn />} variant="h6" sx={{ p: 2 }}>{data.name}</TypographyWithIcon>
                <TableContainer sx={{ height: { xs: "calc(100vh - 120px)", sm: "calc(100vh - 128px)" } }}>
                    <MaterialDataGrid data={materials} isFetching={isFetchingMaterials} />
                </TableContainer>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <TypographyWithIcon startIcon={<LocationOn />} variant="h6" sx={{ p: 2 }}>{data.name}</TypographyWithIcon>
                <TableContainer sx={{ height: { xs: "calc(100vh - 120px)", sm: "calc(100vh - 128px)" } }}>
                    <TrailDataGrid data={trails} isFetching={isFetchingTrails} />
                </TableContainer>
            </TabPanel>
        </VerticalTabs >
    );
};