import { Add, Close, Done } from "@mui/icons-material";
import { Autocomplete, Box, Button, ButtonGroup, Container, DialogActions, DialogContent, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { LayoutDialog, newGuid, useLayout } from "wcz-layout";
import CycleCount from "../../models/CycleCount";
import CycleCountItem from "../../models/CycleCountItem";
import { CycleCountItemStatus } from "../../models/enums/CycleCountItemStatus";
import { CycleCountStatus } from "../../models/enums/CycleCountStatus";
import { useUpdateCycleCount, useUpdateCycleCountItem } from "../../queries/CycleCountQueries";
import { useGetLocations } from "../../queries/LocationQueries";
import { useGetMaterials } from "../../queries/MaterialQueries";

interface StartedUserCycleCountProps {
    cycleCount: CycleCount;
}

export const StartedUserCycleCount: React.FC<StartedUserCycleCountProps> = ({ cycleCount }) => {
    const { t, snackbar } = useLayout();
    const [newItem, setNewItem] = useState<CycleCountItem>({} as CycleCountItem);
    const navigate = useNavigate();

    const { mutate: updateCycleCountItem } = useUpdateCycleCountItem(cycleCount.id);

    const initializeNewItem = useCallback(() => setNewItem({
        ...newItem,
        id: newGuid(),
        status: CycleCountItemStatus.New,
        expectedQuantity: 0,
        foundQuantity: 1,
    }), [newItem, cycleCount]);

    const { mutate: updateCycleCount } = useUpdateCycleCount({
        onSuccess: () => {
            navigate("/");
            snackbar({ title: `${cycleCount.name} ${t("Finished").toLowerCase()}` });
        }
    });

    const finishCycleCount = useCallback(() => updateCycleCount({ ...cycleCount, status: CycleCountStatus.Finished }), [cycleCount]);

    const { data: materials = [] } = useGetMaterials({ enabled: !!newItem.id });
    const { data: locations = [] } = useGetLocations({ enabled: !!newItem.material && !newItem.location }, { isWarehouse: false });

    const addCycleCountItem = useCallback(() => {
        updateCycleCountItem(newItem);
        setNewItem({} as CycleCountItem);
    }, [newItem]);

    const anyItemWaiting = useMemo(() => cycleCount.items?.some(item => item.status === CycleCountItemStatus.Waiting), [cycleCount]);

    return (
        <Container sx={{ my: 2 }}>
            <List subheader={<Box textAlign="center" mb={2}>
                <Typography variant="h6" >{cycleCount.name}</Typography>
            </Box>}>

                {cycleCount.items?.map(item =>
                    <Box id={item.id} key={item.id}>
                        <ListItem secondaryAction={
                            <ButtonGroup>
                                <Button color="success" variant={item.foundQuantity === 1 ? "contained" : "outlined"} onClick={() => updateCycleCountItem({ ...item, foundQuantity: 1 })}><Done /></Button>
                                <Button color="error" variant={item.foundQuantity === 0 ? "contained" : "outlined"} onClick={() => updateCycleCountItem({ ...item, foundQuantity: 0 })}><Close /></Button>
                            </ButtonGroup>
                        }>

                            <ListItemIcon>
                                {item.status === CycleCountItemStatus.Ok && <Tooltip title={t("CorrespondsToTheSystem")}><Done color="success" /></Tooltip>}
                                {item.status === CycleCountItemStatus.Updated && <Tooltip title={t("DoesNotCorrespondToTheSystem")}><Close color="error" /></Tooltip>}
                                {item.status === CycleCountItemStatus.New && <Tooltip title={t("ManuallyAdded")}><Add color="info" /></Tooltip>}
                            </ListItemIcon>

                            <ListItemText primary={item.material?.name ?? item.scannedValue} secondary={
                                <Fragment>
                                    {item.material?.serialNumber && <Typography variant="body2"><b>SN:</b> {item.material.serialNumber}</Typography>}
                                    {item.material?.imei && <Typography variant="body2"><b>IMEI:</b> {item.material.imei}</Typography>}
                                    {item.material?.fixAsset && <Typography variant="body2"><b>FixAsset:</b> {item.material.fixAsset}</Typography>}
                                </Fragment>
                            } />
                        </ListItem>
                        <Divider />
                    </Box>
                )}

                <Stack direction="row" spacing={1} justifyContent="end" sx={{ mt: 2 }}>
                    <Button variant="outlined" startIcon={<Add />} onClick={initializeNewItem}>{t("Add")}</Button>
                    <Button variant="contained" color="success" startIcon={<Done />} onClick={finishCycleCount} disabled={anyItemWaiting}>{t("Finish")}</Button>
                </Stack>
            </List >

            <LayoutDialog open={!!newItem.id} onClose={() => setNewItem({} as CycleCountItem)} title={t("NewMaterial")}>
                <DialogContent>
                    <Autocomplete
                        value={newItem.material}
                        options={materials}
                        getOptionLabel={o => `${o.name} (${o.serialNumber ?? o.imei ?? o.fixAsset})`}
                        autoHighlight
                        onChange={(e, value) => setNewItem({ ...newItem, material: value ?? undefined, location: value?.location ?? newItem.location })}
                        renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Material")} margin="dense" required />}
                    />

                    {!!newItem.material &&
                        <Autocomplete
                            value={newItem.location}
                            options={locations}
                            getOptionLabel={o => o.name}
                            autoHighlight
                            disableClearable
                            onChange={(e, value) => setNewItem({ ...newItem, location: value })}
                            renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Location")} margin="dense" required />}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={addCycleCountItem} autoFocus disabled={!(newItem.material && newItem.location)}>{t("Submit")}</Button>
                </DialogActions>
            </LayoutDialog>
        </Container >
    );
}