import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Error, LayoutContext, fetchGet } from "wcz-layout";
import { peoplesoftUrl } from "../utils/BaseUrl";

export const useGetPeoplesoftDepartments = (options?: Omit<DefinedInitialDataOptions<string[], Error, string[]>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<string[], Error, string[]>({
        ...options,
        queryKey: ["departments"],
        queryFn: ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/department/all`, signal),
        select: (data) => data.map((i: any) => i.departmentId).sort((a, b) => a - b),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};