import { GridRowId } from "@mui/x-data-grid-premium";
import { DefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext, fetchGet, fetchPost, fetchPut, fetchDelete, Error } from "wcz-layout";
import { baseUrl } from "../utils/BaseUrl";
import Employee, { initEmployee } from "../models/Employee";
import { EmployeeStatus } from "wcz-layout/dist/src/models/types/EmployeeStatus";

const route: string = "v1/Employee";

interface GetEmployeeParams {
    status?: EmployeeStatus;
    department?: number;
    onlyWithPreviousEmployeeId?: boolean;
}

export const useGetEmployees = (options?: Omit<DefinedInitialDataOptions<Employee[], Error, Employee[]>, "queryKey" | "queryFn" | "initialData">, params?: GetEmployeeParams) => {
    const { snackbar } = useContext(LayoutContext);

    const queryParams = new URLSearchParams();
    if (params?.status) queryParams.append("status", params.status.toString());
    if (params?.department) queryParams.append("department", params.department.toString());
    if (params?.onlyWithPreviousEmployeeId) queryParams.append("onlyWithPreviousEmployeeId", params.onlyWithPreviousEmployeeId.toString());

    const query = useQuery<Employee[], Error, Employee[]>({
        ...options,
        queryKey: [route, queryParams],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}?${queryParams.toString()}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetEmployee = (id: GridRowId, options?: Omit<DefinedInitialDataOptions<Employee, Error, Employee>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<Employee, Error, Employee>({
        ...options,
        queryKey: [route, id],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/${id}`, signal),
        initialData: initEmployee,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useCreateEmployee = (options?: Omit<UseMutationOptions<Employee, Error, Employee>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<Employee, Error, Employee>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Employee[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useUpdateEmployee = (options?: Omit<UseMutationOptions<Employee, Error, Employee>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<Employee, Error, Employee>({
        ...options,
        mutationFn: model => fetchPut(`${baseUrl}/${route}/${model.id}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Employee[]) => old.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData([route, model.id]);
            if (previousData)
                queryClient.setQueryData([route, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);
            queryClient.setQueryData([route, context.previousData.employeeId], context.previousData);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useDeleteEmployee = (options?: Omit<UseMutationOptions<GridRowId, Error, GridRowId>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<GridRowId, Error, GridRowId>({
        ...options,
        mutationFn: id => fetchDelete(`${baseUrl}/${route}/${id}`),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Employee[]) => old.filter(prev => prev.id !== id));

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};