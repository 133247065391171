import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

interface UploadButtonProps extends Omit<ButtonProps, "onChange"> {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UploadButton: React.FC<UploadButtonProps> = ({ onChange, children, ...props }) => {
    return (
        <Button component="label" role={undefined} tabIndex={-1} {...props}>
            {children}
            <VisuallyHiddenInput type="file" onChange={onChange} />
        </Button>
    );
};