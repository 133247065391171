import MaterialType from "./MaterialType";
import Location from "./Location";

export default interface MaterialOptions {
    partNumbers: string[],
    types: MaterialType[],
    manufacturers: string[],
    models: string[],
    currencies: string[],
    locations: Location[],
    companies: string[],
    highestFixAsset?: number,
    highestItId?: number,
}

export const initMaterialOptions: MaterialOptions = {
    partNumbers: [],
    types: [],
    manufacturers: [],
    models: [],
    currencies: [],
    locations: [],
    companies: [],
}