export default interface LocationOptions {
    buildings: string[],
    equipmentRooms: string[],
    racks: string[],
    shelfs: string[],
    boxes: string[]
}

export const initLocationOptions: LocationOptions = {
    buildings: [],
    equipmentRooms: [],
    racks: [],
    shelfs: [],
    boxes: []
}