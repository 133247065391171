import { environment } from "wcz-layout";

export const baseUrl = ({
    "localhost": "http://localhost:8080",
    "development": "https://api.dev.wistron.eu/it-eqroom",
    "production": "https://api.wistron.eu/it-eqroom"
})[environment];

export const peoplesoftUrl = "https://api.wistron.eu/ps";

export const fileUrl = ({
    "localhost": "https://api.dev.wistron.eu/file",
    "development": "https://api.dev.wistron.eu/file",
    "production": "https://api.wistron.eu/file"
})[environment];