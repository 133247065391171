import { EmployeeStatus } from "./enums/EmployeeStatus";

export default interface Employee {
    id: string,
    firstName: string,
    lastName: string,
    department: string,
    email: string | null,
    status: EmployeeStatus,
    shouldBeInactivated: boolean | null,
    terminationFutureDate: string | null,
    previousEmployeeId: string | null,
    company: string | null
}

export const initEmployee: Employee = {
    id: "",
    firstName: "",
    lastName: "",
    department: "",
    email: null,
    status: EmployeeStatus.Active,
    shouldBeInactivated: null,
    terminationFutureDate: null,
    previousEmployeeId: null,
    company: null
};