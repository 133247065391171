import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GridToolbar, LayoutContext, TableContainer } from "wcz-layout";
import Location from "../../models/Location";
import { useGetLocations } from "../../queries/LocationQueries";
import { LocationOn } from "@mui/icons-material";

export const LocationsPage: React.FC = () => {
    const { t } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data, isFetching } = useGetLocations();

    const handleOnRowDoubleClick = (params: GridRowParams) => navigate(`/locations/${params.id}`);

    const columns: GridColDef<Location>[] = [
        {
            field: "actions", type: "actions", width: 50, getActions: ({ id }) => [
                <GridActionsCellItem key="material" label={t("Material")} icon={<LocationOn />} onClick={() => navigate(`/locations/${id}`)} />,
            ],
        },
        { field: "isWarehouse", headerName: t("IsWarehouse"), width: 150, type: "boolean", },
        { field: "name", headerName: t("Name"), width: 200, },
        { field: "building", headerName: t("Building"), },
        { field: "equipmentRoom", headerName: t("EquipmentRoom"), width: 150, },
        { field: "rack", headerName: t("Rack"), },
        { field: "shelf", headerName: t("Shelf"), },
        { field: "box", headerName: t("Box"), },
        { field: "remark", headerName: t("Remark"), width: 350, },
    ];

    return (
        <TableContainer>
            <DataGridPremium
                rows={data}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        actions: [
                            //<Button key="types" size="small" startIcon={<Category />}>{t("Types")}</Button>,
                        ]
                    }
                }}
                onRowDoubleClick={handleOnRowDoubleClick}
                loading={isFetching}
                ignoreDiacritics
            />
        </TableContainer>
    );
};