import { AdUnits, Delete, DevicesOther, Dock, Headphones, Keyboard, Laptop, LocalPrintshop, MobileFriendly, Monitor, Mouse, PhoneAndroid, Phonelink, Power, Print, Router, ScreenLockPortrait, SdCard, SimCard, Tablet, Tv, Videocam } from "@mui/icons-material";
import Material from "../../models/Material";

interface MaterialIconProps {
    material: Material;
    color?: | "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
}

export const MaterialIcon: React.FC<MaterialIconProps> = ({ material, color }) => {
    switch (material.type) {
        case "Private Mobile": return <MobileFriendly color={color ?? "primary"} />;
        case "Notebook": return <Laptop color={color ?? "primary"} />;
        case "Desktop": return <AdUnits fill="1" color={color ?? "primary"} />;
        case "Monitor": return <Monitor color={color ?? "primary"} />;
        case "Tablet": return <Tablet color={color ?? "primary"} />;
        case "Mobile": return <PhoneAndroid color={color ?? "primary"} />;
        case "Printer - Network": return <Print color={color ?? "primary"} />;
        case "Printer - Non network": return <LocalPrintshop color={color ?? "primary"} />;
        case "SIM card": return <SimCard color={color ?? "primary"} />;
        case "E-SIM card": return <SimCard color={color ?? "primary"} />;
        case "Keyboard": return <Keyboard color={color ?? "primary"} />;
        case "Mouse": return <Mouse color={color ?? "primary"} />;
        case "Network Accessories": return <Router color={color ?? "primary"} />;
        case "Projector": return <Videocam color={color ?? "primary"} />;
        case "TV": return <Tv color={color ?? "primary"} />;
        case "Phone Accessories": return <Phonelink color={color ?? "primary"} />;
        case "Audio Accessories": return <Headphones color={color ?? "primary"} />;
        case "Data Accessories": return <SdCard color={color ?? "primary"} />;
        case "Power Accessories": return <Power color={color ?? "primary"} />;
        case "Numeric keypad": return <Keyboard color={color ?? "primary"} />;
        case "Visitor access card": return <ScreenLockPortrait color={color ?? "primary"} />;
        case "Toner": return <LocalPrintshop color={color ?? "primary"} />;
        case "Waste Toner Bottle": return <Delete color={color ?? "primary"} />;
        case "Docking Station": return <Dock color={color ?? "primary"} />;
        case "PDA": return <DevicesOther color={color ?? "primary"} />;
        case "Video Accessories": return <Videocam color={color ?? "primary"} />;
        default: return <DevicesOther color={color ?? "primary"} />;
    }
};