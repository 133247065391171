import CycleCountItem from "./CycleCountItem";
import Employee from "./Employee";
import { CycleCountStatus } from "./enums/CycleCountStatus";
import { Team } from "./enums/Team";

export default interface CycleCount {
    id: string,
    name: string
    startDate: string | null,
    endDate: string | null,
    status: CycleCountStatus,
    items: CycleCountItem[],
    team?: Team,
    employee?: Employee
}

export const initCycleCount: CycleCount = {
    id: "",
    name: "",
    startDate: null,
    endDate: null,
    status: CycleCountStatus.Started,
    items: [],
};