export default interface PaginationResponse<T> {
    data: T[],
    currentPage: number,
    totalPages: number,
    totalCount: number,
    pageSize: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean
}

export const initialPaginationResponse = <T>(): PaginationResponse<T> => ({
    data: [],
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    pageSize: 10,
    hasPreviousPage: false,
    hasNextPage: false
});