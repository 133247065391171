import { GroupAdd } from "@mui/icons-material";
import { Chip, DialogContent, Stack } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { LayoutContext, LayoutDialog } from "wcz-layout";
import { useGetEmployees } from "../../../../queries/EmployeeQueries";
import { SearchEmployeeCard } from "../SearchEmployeeCard";

export default function EmployeesWithNewId() {
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useContext(LayoutContext);

    const { data } = useGetEmployees({}, { onlyWithPreviousEmployeeId: true });

    if (!data.length) return null;

    return (
        <Fragment>
            <Chip
                label={`${t("EmployeesWithNewId")} (${data.length})`}
                icon={<GroupAdd />}
                onClick={() => setOpen(true)}
                color={data.length ? "info" : "default"}
            />

            <LayoutDialog open={open} onClose={() => setOpen(false)} title={t("EmployeesWithNewId")} color="error" maxWidth="md">
                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                        {data.map(employee => <SearchEmployeeCard key={employee.id} employee={employee} />)}
                    </Stack>
                </DialogContent>
            </LayoutDialog>
        </Fragment>
    );
}