import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { BarcodeReader } from "dynamsoft-javascript-barcode";
import packageJson from "../package.json";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY!);
BarcodeReader.license = process.env.REACT_APP_DYNAMSOFT_LICENSE_KEY!;
BarcodeReader.engineResourcePath = `https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@${packageJson.dependencies["dynamsoft-javascript-barcode"]}/dist/`;


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();